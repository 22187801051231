// Authentication and authorization
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SIGNUP_FAIL = 'SIGNUP_FAIL'
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS'
export const ACTIVATION_FAIL = 'ACTIVATION_FAIL'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOADED_USER_SUCCESS = 'LOADED_USER_SUCCESS'
export const LOADED_USER_FAIL = 'LOADED_USER_FAIL'
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS'
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL'
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS'
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL'
export const PASSWORD_RESET_CONFIRM_SUCCESS = 'PASSWORD_RESET_CONFIRM_SUCCESS'
export const PASSWORD_RESET_CONFIRM_FAIL = 'PASSWORD_RESET_CONFIRM_FAIL'
export const GOOGLE_AUTH_SUCCESS = 'GOOGLE_AUTH_SUCCESS'
export const GOOGLE_AUTH_FAIL = 'GOOGLE_AUTH_FAIL'
export const LOGOUT = 'LOGOUT'

// Dashboard
export const BALANCE_FETCH_SUCCESS = 'BALANCE_FETCH_SUCCESS'
export const BALANCE_FETCH_FAIL = 'BALANCE_FETCH_FAIL'
export const DASHBOARD_FETCH_SUCCESS = 'DASHBOARD_FETCH_SUCCESS'
export const DASHBOARD_FETCH_FAIL = 'DASHBOARD_FETCH_FAIL'
export const TRAYS_SOLD_FETCH_SUCCESS = 'TRAYS_SOLD_FETCH_SUCCESS'
export const TRAYS_SOLD_FETCH_FAIL = 'TRAYS_SOLD_FETCH_FAIL'
export const OVERPAID_FETCH_SUCCESS = 'OVERPAID_FETCH_SUCCESS'
export const OVERPAID_FETCH_FAIL = 'OVERPAID_FETCH_FAIL'

export const BIRDS_FETCH_SUCCESS = 'BIRDS_FETCH_SUCCESS'
export const BIRDS_FETCH_FAIL = 'BALANCE_FETCH_FAIL'

export const DAILYCHART_FETCH_SUCCESS = 'DAILYCHART_FETCH_SUCCESS'
export const DAILYCHART_FETCH_FAIL = 'DAILYCHART_FETCH_FAIL'
export const MONTHLYCHART_FETCH_SUCCESS = 'MONTHLYCHART_FETCH_SUCCESS'
export const MONTHLYCHART_FETCH_FAIL = 'MONTHLYCHART_FETCH_FAIL'
export const YEARLYCHART_FETCH_SUCCESS = 'YEARLYCHART_FETCH_SUCCESS'
export const YEARLYCHART_FETCH_FAIL = 'YEARLYCHART_FETCH_FAIL'

// Customer
export const CUSTOMER_SEARCH_SUCCESS = 'CUSTOMER_SEARCH_SUCCESS'
export const CUSTOMER_SEARCH_FAIL = 'CUSTOMER_SEARCH_FAIL'
export const CUSTOMER_ONLY_FETCH_SUCCESS = 'CUSTOMER_ONLY_FETCH_SUCCESS'
export const CUSTOMER_ONLY_FETCH_FAIL = 'CUSTOMER_ONLY_FETCH_FAIL'
export const CUSTOMER_FETCH_ALL_SUCCESS = 'CUSTOMER_FETCH_ALL_SUCCESS'
export const CUSTOMER_FETCH_ALL_FAIL = 'CUSTOMER_FETCH_ALL_FAIL'
export const CUSTOMER_DELETE_SUCCESS = 'CUSTOMER_DELETE_SUCCESS'
export const CUSTOMER_DELETE_FAIL = 'CUSTOMER_DELETE_FAIL'
export const CUSTOMER_UPDATE_LIST = 'CUSTOMER_UPDATE_LIST '
export const CUSTOMER_FETCH_DETAILS_SUCCESS = 'CUSTOMER_FETCH_DETAILS_SUCCESS,'
export const CUSTOMER_FETCH_DETAILS_FAIL = 'CUSTOMER_FETCH_DETAILS_FAIL,'
export const SAVE_CUSTOMER_SUCCESS = 'SAVE_CUSTOMER_SUCCESS'
export const SAVE_CUSTOMER_FAIL = 'SAVE_CUSTOMER_FAIL'
export const EDIT_CUSTOMER_SUCCESS = 'EDIT_CUSTOMER_SUCCESS'
export const EDIT_CUSTOMER_FAIL = 'EDIT_CUSTOMER_FAIL'

// orders
export const SAVE_ORDERS_SUCCESS = 'SAVE_ORDERS_SUCCESS'
export const SAVE_ORDERS_FAIL = 'SAVE_ORDERS_FAIL'
export const EDIT_ORDERS_SUCCESS = 'EDIT_ORDERS_SUCCESS'
export const EDIT_ORDERS_FAIL = 'EDIT_ORDERS_FAIL'
export const ORDERS_FETCH_ALL_SUCCESS = 'ORDERS_FETCH_ALL_SUCCESS'
export const ORDERS_FETCH_ALL_FAIL = 'ORDERS_FETCH_ALL_FAIL'
export const ORDER_DELETE_SUCCESS = 'ORDER_DELETE_SUCCESS'
export const ORDER_DELETE_FAIL = 'ORDER_DELETE_FAIL'
export const ORDER_UPDATE_LIST = 'ORDER_UPDATE_LIST '
export const ORDERS_FETCH_DETAILS_SUCCESS = 'ORDERS_FETCH_DETAILS_SUCCESS'
export const ORDERS_FETCH_DETAILS_FAIL = 'ORDERS_FETCH_DETAILS_FAIL'
export const ORDER_SEARCH_SUCCESS = 'ORDER_SEARCH_SUCCESS'
export const ORDER_SEARCH_FAIL = 'ORDER_SEARCH_FAIL'

// Invoice
export const SAVE_INVOICE_SUCCESS = 'SAVE_INVOICE_SUCCESS'
export const SAVE_INVOICE_FAIL = 'SAVE_INVOICE_FAIL'
export const EDIT_INVOICE_SUCCESS = 'EDIT_INVOICE_SUCCESS'
export const EDIT_INVOICE_FAIL = 'EDIT_INVOICE_FAIL'
export const INVOICE_FETCH_ALL_SUCCESS = 'INVOICE_FETCH_ALL_SUCCESS'
export const INVOICE_FETCH_ALL_FAIL = 'INVOICE_FETCH_ALL_FAIL'
export const INVOICE_DELETE_SUCCESS = 'INVOICE_DELETE_SUCCESS'
export const INVOICE_DELETE_FAIL = 'INVOICE_DELETE_FAIL'
export const INVOICE_UPDATE_LIST = 'INVOICE_UPDATE_LIST '
export const INVOICE_FETCH_DETAILS_SUCCESS = 'INVOICE_FETCH_DETAILS_SUCCESS'
export const INVOICE_FETCH_DETAILS_FAIL = 'INVOICE_FETCH_DETAILS_FAIL'
export const INVOICE_SEARCH_SUCCESS = 'INVOICE_SEARCH_SUCCESS'
export const INVOICE_SEARCH_FAIL = 'INVOICE_SEARCH_FAIL'

// Payments
export const PAYMENTS_FETCH_ALL_SUCCESS = 'PAYMENTS_FETCH_ALL_SUCCESS'
export const PAYMENTS_FETCH_ALL_FAIL = 'PAYMENTS_FETCH_ALL_FAIL'
export const PAYMENT_DELETE_SUCCESS = 'PAYMENT_DELETE_SUCCESS'
export const PAYMENT_DELETE_FAIL = 'PAYMENT_DELETE_FAIL'
export const PAYMENT_UPDATE_LIST = 'PAYMENT_UPDATE_LIST '
export const PAYMENTS_FETCH_DETAILS_SUCCESS = 'PAYMENTS_FETCH_DETAILS_SUCCESS'
export const PAYMENTS_FETCH_DETAILS_FAIL = 'PAYMENTS_FETCH_DETAILS_FAIL'
export const SAVE_PAYMENTS_SUCCESS = 'SAVE_PAYMENTS_SUCCESS'
export const SAVE_PAYMENTS_FAIL = 'SAVE_PAYMENTS_FAIL'
export const EDIT_PAYMENTS_SUCCESS = 'EDIT_PAYMENTS_SUCCESS'
export const EDIT_PAYMENTS_FAIL = 'EDIT_PAYMENTS_FAIL'

// chicken batch
export const BATCH_FETCH_SUCCESS = 'BATCH_FETCH_SUCCESS'
export const BATCH_FETCH_FAIL = 'BATCH_FETCH_FAIL'
export const BATCH_ONLY_FETCH_SUCCESS = 'BATCH_ONLY_FETCH_SUCCESS'
export const BATCH_ONLY_FETCH_FAIL = 'BATCH_ONLY_FETCH_FAIL'
export const SAVE_BATCH_SUCCESS = 'SAVE_BATCH_SUCCESS'
export const SAVE_BATCH_FAIL = 'SAVE_BATCH_FAIL'
export const EDIT_BATCH_SUCCESS = 'EDIT_BATCH_SUCCESS'
export const EDIT_BATCH_FAIL = 'EDIT_BATCH_FAIL'
export const BATCH_FETCH_DETAILS_SUCCESS = 'BATCH_FETCH_DETAILS_SUCCESS'
export const BATCH_FETCH_DETAILS_FAIL = 'BATCH_FETCH_DETAILS_FAIL'
export const DELETE_BATCH_SUCCESS = 'DELETE_BATCH_SUCCESS'
export const DELETE_BATCH_FAIL = 'DELETE_BATCH_FAIL'
export const BATCH_UPDATE_LIST = 'BATCH_UPDATE_LIST '

// Eggs
export const EGGS_FETCH_SUCCESS = 'EGGS_FETCH_SUCCESS'
export const EGGS_FETCH_FAIL = 'EGGS_FETCH_FAIL'
export const SAVE_EGGS_SUCCESS = 'SAVE_EGGS_SUCCESS'
export const SAVE_EGGS_FAIL = 'SAVE_EGGS_FAIL'
export const EDIT_EGGS_SUCCESS = 'EDIT_EGGS_SUCCESS'
export const EDIT_EGGS_FAIL = 'EDIT_EGGS_FAIL'
export const EGGS_FETCH_DETAILS_SUCCESS = 'EGGS_FETCH_DETAILS_SUCCESS'
export const EGGS_FETCH_DETAILS_FAIL = 'EGGS_FETCH_DETAILS_FAIL'
export const DELETE_EGGS_SUCCESS = 'DELETE_EGGS_SUCCESS'
export const DELETE_EGGS_FAIL = 'DELETE_EGGS_FAIL'
export const EGGS_UPDATE_LIST = 'EGGS_UPDATE_LIST'

// Food
export const FOOD_FETCH_SUCCESS = 'FOOD_FETCH_SUCCESS'
export const FOOD_FETCH_FAIL = 'FOOD_FETCH_FAIL'
export const SAVE_FOOD_SUCCESS = 'SAVE_FOOD_SUCCESS'
export const SAVE_FOOD_FAIL = 'SAVE_FOOD_FAIL'
export const EDIT_FOOD_SUCCESS = 'EDIT_FOOD_SUCCESS'
export const EDIT_FOOD_FAIL = 'EDIT_FOOD_FAIL'
export const FOOD_FETCH_DETAILS_SUCCESS = 'FOOD_FETCH_DETAILS_SUCCESS'
export const FOOD_FETCH_DETAILS_FAIL = 'FOOD_FETCH_DETAILS_FAIL'
export const DELETE_FOOD_SUCCESS = 'DELETE_FOOD_SUCCESS'
export const DELETE_FOOD_FAIL = 'DELETE_FOOD_FAIL'
export const FOOD_UPDATE_LIST = 'FOOD_UPDATE_LIST'

// Mortality
export const MORTALITY_FETCH_SUCCESS = 'MORTALITY_FETCH_SUCCESS'
export const MORTALITY_FETCH_FAIL = 'MORTALITY_FETCH_FAIL'
export const SAVE_MORTALITY_SUCCESS = 'SAVE_MORTALITY_SUCCESS'
export const SAVE_MORTALITY_FAIL = 'SAVE_MORTALITY_FAIL'
export const EDIT_MORTALITY_SUCCESS = 'EDIT_MORTALITY_SUCCESS'
export const EDIT_MORTALITY_FAIL = 'EDIT_MORTALITY_FAIL'
export const MORTALITY_FETCH_DETAILS_SUCCESS = 'MORTALITY_FETCH_DETAILS_SUCCESS'
export const MORTALITY_FETCH_DETAILS_FAIL = 'MORTALITY_FETCH_DETAILS_FAIL'
export const DELETE_MORTALITY_SUCCESS = 'DELETE_MORTALITY_SUCCESS'
export const DELETE_MORTALITY_FAIL = 'DELETE_MORTALITY_FAIL'
export const MORTALITY_UPDATE_LIST = 'MORTALITY_UPDATE_LIST'

// Medicine and vaccine
export const MEDS_FETCH_SUCCESS = 'MEDS_FETCH_SUCCESS'
export const MEDS_FETCH_FAIL = 'MEDS_FETCH_FAIL'
export const SAVE_MEDS_SUCCESS = 'SAVE_MEDS_SUCCESS'
export const SAVE_MEDS_FAIL = 'SAVE_MEDS_FAIL'
export const EDIT_MEDS_SUCCESS = 'EDIT_MEDS_SUCCESS'
export const EDIT_MEDS_FAIL = 'EDIT_MEDS_FAIL'
export const MEDS_FETCH_DETAILS_SUCCESS = 'MEDS_FETCH_DETAILS_SUCCESS'
export const MEDS_FETCH_DETAILS_FAIL = 'MEDS_FETCH_DETAILS_FAIL'
export const DELETE_MEDS_SUCCESS = 'DELETE_MEDS_SUCCESS'
export const DELETE_MEDS_FAIL = 'DELETE_MEDS_FAIL'
export const MEDS_UPDATE_LIST = 'MEDS_UPDATE_LIST'

// Meat Production
export const MEAT_FETCH_SUCCESS = 'MEAT_FETCH_SUCCESS'
export const MEAT_FETCH_FAIL = 'MEAT_FETCH_FAIL'
export const SAVE_MEAT_SUCCESS = 'SAVE_MEAT_SUCCESS'
export const SAVE_MEAT_FAIL = 'SAVE_MEAT_FAIL'
export const EDIT_MEAT_SUCCESS = 'EDIT_MEAT_SUCCESS'
export const EDIT_MEAT_FAIL = 'EDIT_MEAT_FAIL'
export const MEAT_FETCH_DETAILS_SUCCESS = 'MEAT_FETCH_DETAILS_SUCCESS'
export const MEAT_FETCH_DETAILS_FAIL = 'MEAT_FETCH_DETAILS_FAIL'
export const DELETE_MEAT_SUCCESS = 'DELETE_MEAT_SUCCESS'
export const DELETE_MEAT_FAIL = 'DELETE_MEAT_FAIL'
export const MEAT_UPDATE_LIST = 'MEAT_UPDATE_LIST'

// Employee Management
export const EMPLOYEE_FETCH_SUCCESS = 'EMPLOYEE_FETCH_SUCCESS'
export const EMPLOYEE_FETCH_FAIL = 'EMPLOYEE_FETCH_FAIL'
export const SAVE_EMPLOYEE_SUCCESS = 'SAVE_EMPLOYEE_SUCCESS'
export const SAVE_EMPLOYEE_FAIL = 'SAVE_EMPLOYEE_FAIL'
export const EMPLOYEE_ONLY_FETCH_SUCCESS = 'EMPLOYEE_ONLY_FETCH_SUCCESS'
export const EMPLOYEE_ONLY_FETCH_FAIL = 'EMPLOYEE_ONLY_FETCH_FAIL'
export const EDIT_EMPLOYEE_SUCCESS = 'EDIT_EMPLOYEE_SUCCESS'
export const EDIT_EMPLOYEE_FAIL = 'EDIT_EMPLOYEE_FAIL'
export const EMPLOYEE_FETCH_DETAILS_SUCCESS = 'EMPLOYEE_FETCH_DETAILS_SUCCESS'
export const EMPLOYEE_FETCH_DETAILS_FAIL = 'EMPLOYEE_FETCH_DETAILS_FAIL'
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS'
export const DELETE_EMPLOYEE_FAIL = 'DELETE_EMPLOYEE_FAIL'
export const EMPLOYEE_UPDATE_LIST = 'EMPLOYEE_UPDATE_LIST'

// Employee Payment Management
export const EMPLOYEE_PAYMENT_FETCH_SUCCESS = 'EMPLOYEE_PAYMENT_FETCH_SUCCESS'
export const EMPLOYEE_PAYMENT_FETCH_FAIL = 'EMPLOYEE_PAYMENT_FETCH_FAIL'
export const SAVE_EMPLOYEE_PAYMENT_SUCCESS = 'SAVE_EMPLOYEE_PAYMENT_SUCCESS'
export const SAVE_EMPLOYEE_PAYMENT_FAIL = 'SAVE_EMPLOYEE_PAYMENT_FAIL'
export const EDIT_EMPLOYEE_PAYMENT_SUCCESS = 'EDIT_EMPLOYEE_PAYMENT_SUCCESS'
export const EDIT_EMPLOYEE_PAYMENT_FAIL = 'EDIT_EMPLOYEE_PAYMENT_FAIL'
export const EMPLOYEE_PAYMENT_FETCH_DETAILS_SUCCESS = 'EMPLOYEE_PAYMENT_FETCH_DETAILS_SUCCESS'
export const EMPLOYEE_PAYMENT_FETCH_DETAILS_FAIL = 'EMPLOYEE_PAYMENT_FETCH_DETAILS_FAIL'
export const DELETE_EMPLOYEE_PAYMENT_SUCCESS = 'DELETE_EMPLOYEE_PAYMENT_SUCCESS'
export const DELETE_EMPLOYEE_PAYMENT_FAIL = 'DELETE_EMPLOYEE_PAYMENT_FAIL'
export const EMPLOYEE_PAYMENT_UPDATE_LIST = 'EMPLOYEE_PAYMENT_UPDATE_LIST'

// Food cost
export const FEED_COST_FETCH_SUCCESS = 'FEED_COST_FETCH_SUCCESS'
export const FEED_COST_FETCH_FAIL = 'FEED_COST_FETCH_FAIL'
export const SAVE_FEED_COST_SUCCESS = 'SAVE_FEED_COST_SUCCESS'
export const SAVE_FEED_COST_FAIL = 'SAVE_FEED_COST_FAIL'
export const EDIT_FEED_COST_SUCCESS = 'EDIT_FEED_COST_SUCCESS'
export const EDIT_FEED_COST_FAIL = 'EDIT_FEED_COST_FAIL'
export const FEED_COST_FETCH_DETAILS_SUCCESS = 'FEED_COST_FETCH_DETAILS_SUCCESS'
export const FEED_COST_FETCH_DETAILS_FAIL = 'FEED_COST_FETCH_DETAILS_FAIL'
export const DELETE_FEED_COST_SUCCESS = 'DELETE_FEED_COST_SUCCESS'
export const DELETE_FEED_COST_FAIL = 'DELETE_FEED_COST_FAIL'
export const FEED_COST_UPDATE_LIST = 'FEED_COST_UPDATE_LIST'

// Meds cost
export const MEDS_COST_FETCH_SUCCESS = 'MEDS_COST_FETCH_SUCCESS'
export const MEDS_COST_FETCH_FAIL = 'MEDS_COST_FETCH_FAIL'
export const SAVE_MEDS_COST_SUCCESS = 'SAVE_MEDS_COST_SUCCESS'
export const SAVE_MEDS_COST_FAIL = 'SAVE_MEDS_COST_FAIL'
export const EDIT_MEDS_COST_SUCCESS = 'EDIT_MEDS_COST_SUCCESS'
export const EDIT_MEDS_COST_FAIL = 'EDIT_MEDS_COST_FAIL'
export const MEDS_COST_FETCH_DETAILS_SUCCESS = 'MEDS_COST_FETCH_DETAILS_SUCCESS'
export const MEDS_COST_FETCH_DETAILS_FAIL = 'MEDS_COST_FETCH_DETAILS_FAIL'
export const DELETE_MEDS_COST_SUCCESS = 'DELETE_MEDS_COST_SUCCESS'
export const DELETE_MEDS_COST_FAIL = 'DELETE_MEDS_COST_FAIL'
export const MEDS_COST_UPDATE_LIST = 'MEDS_COST_UPDATE_LIST'

// Fuel cost
export const FUEL_COST_FETCH_SUCCESS = 'FUEL_COST_FETCH_SUCCESS'
export const FUEL_COST_FETCH_FAIL = 'FUEL_COST_FETCH_FAIL'
export const SAVE_FUEL_COST_SUCCESS = 'SAVE_FUEL_COST_SUCCESS'
export const SAVE_FUEL_COST_FAIL = 'SAVE_FUEL_COST_FAIL'
export const EDIT_FUEL_COST_SUCCESS = 'EDIT_FUEL_COST_SUCCESS'
export const EDIT_FUEL_COST_FAIL = 'EDIT_FUEL_COST_FAIL'
export const FUEL_COST_FETCH_DETAILS_SUCCESS = 'FUEL_COST_FETCH_DETAILS_SUCCESS'
export const FUEL_COST_FETCH_DETAILS_FAIL = 'FUEL_COST_FETCH_DETAILS_FAIL'
export const DELETE_FUEL_COST_SUCCESS = 'DELETE_FUEL_COST_SUCCESS'
export const DELETE_FUEL_COST_FAIL = 'DELETE_FUEL_COST_FAIL'
export const FUEL_COST_UPDATE_LIST = 'FUEL_COST_UPDATE_LIST'

// Procurement cost
export const PROCUREMENT_COST_FETCH_SUCCESS = 'PROCUREMENT_COST_FETCH_SUCCESS'
export const PROCUREMENT_COST_FETCH_FAIL = 'PROCUREMENT_COST_FETCH_FAIL'
export const SAVE_PROCUREMENT_COST_SUCCESS = 'SAVE_PROCUREMENT_COST_SUCCESS'
export const SAVE_PROCUREMENT_COST_FAIL = 'SAVE_PROCUREMENT_COST_FAIL'
export const EDIT_PROCUREMENT_COST_SUCCESS = 'EDIT_PROCUREMENT_COST_SUCCESS'
export const EDIT_PROCUREMENT_COST_FAIL = 'EDIT_PROCUREMENT_COST_FAIL'
export const PROCUREMENT_COST_FETCH_DETAILS_SUCCESS = 'PROCUREMENT_COST_FETCH_DETAILS_SUCCESS'
export const PROCUREMENT_COST_FETCH_DETAILS_FAIL = 'PROCUREMENT_COST_FETCH_DETAILS_FAIL'
export const DELETE_PROCUREMENT_COST_SUCCESS = 'DELETE_PROCUREMENT_COST_SUCCESS'
export const DELETE_PROCUREMENT_COST_FAIL = 'DELETE_PROCUREMENT_COST_FAIL'
export const PROCUREMENT_COST_UPDATE_LIST = 'PROCUREMENT_COST_UPDATE_LIST'