import Axios from 'axios';
import { 
    
    // auth
    LOGIN_SUCCESS, LOGIN_FAIL, 
    LOADED_USER_SUCCESS, LOADED_USER_FAIL ,
    AUTHENTICATED_SUCCESS, AUTHENTICATED_FAIL,
    PASSWORD_RESET_SUCCESS, PASSWORD_RESET_FAIL,
    PASSWORD_RESET_CONFIRM_SUCCESS, PASSWORD_RESET_CONFIRM_FAIL,
    SIGNUP_SUCCESS, SIGNUP_FAIL,
    ACTIVATION_SUCCESS, ACTIVATION_FAIL,
    GOOGLE_AUTH_SUCCESS, GOOGLE_AUTH_FAIL,
    LOGOUT,

    // customers
    CUSTOMER_SEARCH_SUCCESS, CUSTOMER_SEARCH_FAIL,
    EDIT_CUSTOMER_SUCCESS, EDIT_CUSTOMER_FAIL,
    CUSTOMER_ONLY_FETCH_SUCCESS, CUSTOMER_ONLY_FETCH_FAIL,
    CUSTOMER_FETCH_ALL_SUCCESS, CUSTOMER_FETCH_ALL_FAIL,
    CUSTOMER_DELETE_SUCCESS, CUSTOMER_DELETE_FAIL, CUSTOMER_UPDATE_LIST,
    CUSTOMER_FETCH_DETAILS_SUCCESS, CUSTOMER_FETCH_DETAILS_FAIL, 
    SAVE_CUSTOMER_SUCCESS, SAVE_CUSTOMER_FAIL,

    // payments
    PAYMENTS_FETCH_ALL_SUCCESS, PAYMENTS_FETCH_ALL_FAIL,
    PAYMENTS_FETCH_DETAILS_SUCCESS, PAYMENTS_FETCH_DETAILS_FAIL,
    EDIT_PAYMENTS_SUCCESS, EDIT_PAYMENTS_FAIL,
    SAVE_PAYMENTS_SUCCESS, SAVE_PAYMENTS_FAIL,
    PAYMENT_DELETE_SUCCESS, PAYMENT_DELETE_FAIL, PAYMENT_UPDATE_LIST,

    // orders
    ORDERS_FETCH_ALL_SUCCESS, ORDERS_FETCH_ALL_FAIL,
    ORDERS_FETCH_DETAILS_SUCCESS, ORDERS_FETCH_DETAILS_FAIL,
    ORDER_DELETE_SUCCESS, ORDER_DELETE_FAIL, ORDER_UPDATE_LIST,
    SAVE_ORDERS_SUCCESS, SAVE_ORDERS_FAIL,
    ORDER_SEARCH_SUCCESS, ORDER_SEARCH_FAIL,
    EDIT_ORDERS_SUCCESS, EDIT_ORDERS_FAIL,

    // invoice
    INVOICE_FETCH_ALL_SUCCESS, INVOICE_FETCH_ALL_FAIL,
    INVOICE_FETCH_DETAILS_SUCCESS, INVOICE_FETCH_DETAILS_FAIL,
    INVOICE_DELETE_SUCCESS, INVOICE_DELETE_FAIL, INVOICE_UPDATE_LIST,
    SAVE_INVOICE_SUCCESS, SAVE_INVOICE_FAIL,

    // dashboard
    DASHBOARD_FETCH_SUCCESS, DASHBOARD_FETCH_FAIL,
    BALANCE_FETCH_SUCCESS, BALANCE_FETCH_FAIL,
    BIRDS_FETCH_SUCCESS, BIRDS_FETCH_FAIL,
    DAILYCHART_FETCH_FAIL, DAILYCHART_FETCH_SUCCESS, 
    MONTHLYCHART_FETCH_SUCCESS, MONTHLYCHART_FETCH_FAIL, 
    YEARLYCHART_FETCH_SUCCESS, YEARLYCHART_FETCH_FAIL,
    TRAYS_SOLD_FETCH_SUCCESS, TRAYS_SOLD_FETCH_FAIL,
    OVERPAID_FETCH_SUCCESS, OVERPAID_FETCH_FAIL,

    // chicken batch
    BATCH_FETCH_SUCCESS, BATCH_FETCH_FAIL,
    BATCH_ONLY_FETCH_SUCCESS, BATCH_ONLY_FETCH_FAIL,
    SAVE_BATCH_SUCCESS, SAVE_BATCH_FAIL,
    DELETE_BATCH_SUCCESS, DELETE_BATCH_FAIL, BATCH_UPDATE_LIST,
    BATCH_FETCH_DETAILS_SUCCESS, BATCH_FETCH_DETAILS_FAIL,
    EDIT_BATCH_SUCCESS, EDIT_BATCH_FAIL,

    // eggs collection
    EGGS_FETCH_SUCCESS, EGGS_FETCH_FAIL,
    SAVE_EGGS_SUCCESS, SAVE_EGGS_FAIL,
    EDIT_EGGS_SUCCESS, EDIT_EGGS_FAIL,
    DELETE_EGGS_SUCCESS, DELETE_EGGS_FAIL, EGGS_UPDATE_LIST,
    EGGS_FETCH_DETAILS_SUCCESS, EGGS_FETCH_DETAILS_FAIL,

    // food intake
    FOOD_FETCH_SUCCESS, FOOD_FETCH_FAIL,
    SAVE_FOOD_SUCCESS, SAVE_FOOD_FAIL,
    EDIT_FOOD_SUCCESS, EDIT_FOOD_FAIL,
    DELETE_FOOD_SUCCESS, DELETE_FOOD_FAIL, FOOD_UPDATE_LIST,
    FOOD_FETCH_DETAILS_SUCCESS, FOOD_FETCH_DETAILS_FAIL,
    
    // Mortality rate
    MORTALITY_FETCH_SUCCESS, MORTALITY_FETCH_FAIL,
    SAVE_MORTALITY_SUCCESS, SAVE_MORTALITY_FAIL,
    EDIT_MORTALITY_SUCCESS, EDIT_MORTALITY_FAIL,
    DELETE_MORTALITY_SUCCESS, DELETE_MORTALITY_FAIL, MORTALITY_UPDATE_LIST,
    MORTALITY_FETCH_DETAILS_SUCCESS, MORTALITY_FETCH_DETAILS_FAIL,
    
    // Medicine and Vaccination
    MEDS_FETCH_SUCCESS, MEDS_FETCH_FAIL,
    SAVE_MEDS_SUCCESS, SAVE_MEDS_FAIL,
    EDIT_MEDS_SUCCESS, EDIT_MEDS_FAIL,
    DELETE_MEDS_SUCCESS, DELETE_MEDS_FAIL, MEDS_UPDATE_LIST,
    MEDS_FETCH_DETAILS_SUCCESS, MEDS_FETCH_DETAILS_FAIL,
    
    // Meat Production
    MEAT_FETCH_SUCCESS, MEAT_FETCH_FAIL,
    SAVE_MEAT_SUCCESS, SAVE_MEAT_FAIL,
    EDIT_MEAT_SUCCESS, EDIT_MEAT_FAIL,
    DELETE_MEAT_SUCCESS, DELETE_MEAT_FAIL, MEAT_UPDATE_LIST,
    MEAT_FETCH_DETAILS_SUCCESS, MEAT_FETCH_DETAILS_FAIL,
    
    // Employee Management
    EMPLOYEE_FETCH_SUCCESS, EMPLOYEE_FETCH_FAIL,
    SAVE_EMPLOYEE_SUCCESS, SAVE_EMPLOYEE_FAIL,
    EDIT_EMPLOYEE_SUCCESS, EDIT_EMPLOYEE_FAIL,
    DELETE_EMPLOYEE_SUCCESS, DELETE_EMPLOYEE_FAIL, EMPLOYEE_UPDATE_LIST,
    EMPLOYEE_FETCH_DETAILS_SUCCESS, EMPLOYEE_FETCH_DETAILS_FAIL,
    
    // Employee Payment Management
    EMPLOYEE_PAYMENT_FETCH_SUCCESS, EMPLOYEE_PAYMENT_FETCH_FAIL,
    EMPLOYEE_ONLY_FETCH_SUCCESS, EMPLOYEE_ONLY_FETCH_FAIL,
    SAVE_EMPLOYEE_PAYMENT_SUCCESS, SAVE_EMPLOYEE_PAYMENT_FAIL,
    EDIT_EMPLOYEE_PAYMENT_SUCCESS, EDIT_EMPLOYEE_PAYMENT_FAIL,
    DELETE_EMPLOYEE_PAYMENT_SUCCESS, DELETE_EMPLOYEE_PAYMENT_FAIL, EMPLOYEE_PAYMENT_UPDATE_LIST,
    EMPLOYEE_PAYMENT_FETCH_DETAILS_SUCCESS, EMPLOYEE_PAYMENT_FETCH_DETAILS_FAIL,
    
    // Food COST
    FEED_COST_FETCH_SUCCESS, FEED_COST_FETCH_FAIL,
    SAVE_FEED_COST_SUCCESS, SAVE_FEED_COST_FAIL,
    EDIT_FEED_COST_SUCCESS, EDIT_FEED_COST_FAIL,
    DELETE_FEED_COST_SUCCESS, DELETE_FEED_COST_FAIL, FEED_COST_UPDATE_LIST,
    FEED_COST_FETCH_DETAILS_SUCCESS, FEED_COST_FETCH_DETAILS_FAIL,
    
    // MEDS COST
    MEDS_COST_FETCH_SUCCESS, MEDS_COST_FETCH_FAIL,
    SAVE_MEDS_COST_SUCCESS, SAVE_MEDS_COST_FAIL,
    EDIT_MEDS_COST_SUCCESS, EDIT_MEDS_COST_FAIL,
    DELETE_MEDS_COST_SUCCESS, DELETE_MEDS_COST_FAIL, MEDS_COST_UPDATE_LIST,
    MEDS_COST_FETCH_DETAILS_SUCCESS, MEDS_COST_FETCH_DETAILS_FAIL,
    
    // FUEL COST
    FUEL_COST_FETCH_SUCCESS, FUEL_COST_FETCH_FAIL,
    SAVE_FUEL_COST_SUCCESS, SAVE_FUEL_COST_FAIL,
    EDIT_FUEL_COST_SUCCESS, EDIT_FUEL_COST_FAIL,
    DELETE_FUEL_COST_SUCCESS, DELETE_FUEL_COST_FAIL, FUEL_COST_UPDATE_LIST,
    FUEL_COST_FETCH_DETAILS_SUCCESS, FUEL_COST_FETCH_DETAILS_FAIL,

    // PROCUREMENT COST
    PROCUREMENT_COST_FETCH_SUCCESS, PROCUREMENT_COST_FETCH_FAIL,
    SAVE_PROCUREMENT_COST_SUCCESS, SAVE_PROCUREMENT_COST_FAIL,
    EDIT_PROCUREMENT_COST_SUCCESS, EDIT_PROCUREMENT_COST_FAIL,
    DELETE_PROCUREMENT_COST_SUCCESS, DELETE_PROCUREMENT_COST_FAIL, PROCUREMENT_COST_UPDATE_LIST,
    PROCUREMENT_COST_FETCH_DETAILS_SUCCESS, PROCUREMENT_COST_FETCH_DETAILS_FAIL

} from './types';

// Application authentication and authorization 

export const load_user = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
                'ACCEPT': 'application/json'
            }
        };

        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/users/me/`, config);
            if (!res.ok) {
                throw Error(res.statusText);
            }
            const data = await res.json();

            dispatch({
                type: LOADED_USER_SUCCESS,
                payload: data
            });
        } catch (error) {
            dispatch({
                type: LOADED_USER_FAIL
            });
        }
    } else {
        dispatch({
            type: LOADED_USER_FAIL
        });
    }
}

export const googleAuthenticate = (code, state) => async dispatch => {
    if (code && state && !localStorage.getItem('access')) {
        const details = {
            'code': code,
            'state': state,
        };

        const formBody = Object.keys(details)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key]))
            .join('&');

        const url = `${process.env.REACT_APP_API_URL}/auth/o/google-oauth2/?${formBody}`;

        const config = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'ACCEPT': 'application/x-www-form-urlencoded'
            }
        };

        try {
            const res = await fetch(url, config);
            const data = await res.json();

            dispatch({
                type: GOOGLE_AUTH_SUCCESS,
                payload: data
            });

            dispatch(load_user());
        } catch (error) {
            dispatch({
                type: GOOGLE_AUTH_FAIL
            });
        }
    }
}

export const checkAuthenticated = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-type': 'application/json',
                'ACCEPT': 'application/json'
            }
        }

        const body = JSON.stringify({ token: localStorage.getItem('access') })
        console.log(body)

        try {
            const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/jwt/verify/`, {
                method: 'POST',
                headers: config.headers,
                body: body
            });

            const data = await res.json();

            if (data.code !== 'token not valid') {
                dispatch({
                    type: AUTHENTICATED_SUCCESS
                })
            } else {
                dispatch({
                    type: AUTHENTICATED_FAIL
                })
            }
            
        } catch (error) {
            dispatch({
                type: AUTHENTICATED_FAIL
            })
        }

    } else {
        dispatch({
            type: AUTHENTICATED_FAIL
        })
    }
}

export const login = (email, password) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({ email, password })
    };

    try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/jwt/create/`, config);

        if (res.ok) {
            const data = await res.json();

            dispatch({
                type: LOGIN_SUCCESS,
                payload: data
            });

            dispatch(load_user());

            // Return a success response
            return { success: true };
        } else {
            dispatch({
                type: LOGIN_FAIL
            });

            // Extract the error message and return an error response
            const errorData = await res.json();
            return { error: errorData.detail };
        }
    } catch (error) {
        dispatch({
            type: LOGIN_FAIL
        });

        // Return an error response for network or other errors
        return { error: 'An error occurred. Please try again.' };
    }
}

export const signup = (first_name, last_name, phone, email, user_type, password, re_password) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({ first_name, last_name, phone, user_type, email, password, re_password })
    };

    try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/users/`, config);

        if (res.ok) {
            const data = await res.json();

            dispatch({
                type: SIGNUP_SUCCESS,
                payload: data
            });

          return { success: true, data };

        } else {
          const error = await res.json();
            dispatch({
                type: SIGNUP_FAIL,
                payload: error
            });
 
          return { success: false, error };
        }
    } catch (error) {
        dispatch({
            type: SIGNUP_FAIL
        });
    }
}

export const verify = (uid, token) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({ uid, token })
    };

    try {
        await fetch(`${process.env.REACT_APP_API_URL}/auth/users/activation/`, config);

        dispatch({
            type: ACTIVATION_SUCCESS,
        });
    } catch (error) {
        dispatch({
            type: ACTIVATION_FAIL
        });
    }
}

export const reset_password = (email) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({ email })
    };

    try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/users/reset_password/`, config);

        if (res.ok) {
            const data = await res.json();

            dispatch({
                type: PASSWORD_RESET_SUCCESS,
                payload: data
            });

            dispatch(load_user());
        } else {
            dispatch({
                type: PASSWORD_RESET_FAIL
            });
        }
    } catch (error) {
        dispatch({
            type: PASSWORD_RESET_FAIL
        });
    }
}

export const reset_password_confirm = (uid, token, new_password, re_new_password ) => async dispatch => {
    const config = {
        headers: {
            'Content-type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({ uid, token, new_password, re_new_password })
    };

    try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/users/reset_password_confirm/`, config);

        if (res.ok) {
            const data = await res.json();

            dispatch({
                type: PASSWORD_RESET_CONFIRM_SUCCESS,
                payload: data
            });

            dispatch(load_user());
        } else {
            dispatch({
                type: PASSWORD_RESET_CONFIRM_FAIL
            });
        }
    } catch (error) {
        dispatch({
            type: PASSWORD_RESET_CONFIRM_FAIL
        });
    }
}

export const logout= () => dispatch => {
    dispatch({
        type: LOGOUT
    });
}

// API handler for dashboard

export const fetchBalance = () => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/total-balance/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const balanceData = response.data;

      console.log("Balance Data:", balanceData); // Log the retrieved data

      dispatch({
        type: BALANCE_FETCH_SUCCESS,
        payload: balanceData,
      });

      return balanceData;
    } else {
      console.error("API Request Failed with Status Code:", response.status);
      dispatch({
        type: BALANCE_FETCH_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching balance data:", error);
    dispatch({
      type: BALANCE_FETCH_FAIL,
    });
  }
};

export const fetchBirds = () => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/birds_dashboard/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const birdsData = response.data;

      console.log("Balance Data:", birdsData); // Log the retrieved data

      dispatch({
        type: BIRDS_FETCH_SUCCESS,
        payload: birdsData,
      });

      return birdsData;
    } else {
      console.error("API Request Failed with Status Code:", response.status);
      dispatch({
        type: BIRDS_FETCH_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching balance data:", error);
    dispatch({
      type: BIRDS_FETCH_FAIL,
    });
  }
};

export const fetchTotalTrays = () => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/total-trays/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const traysData = response.data;

      console.log("trays Data:", traysData); // Log the retrieved data

      dispatch({
        type: TRAYS_SOLD_FETCH_SUCCESS,
        payload: traysData,
      });

      return traysData;
    } else {
      console.error("API Request Failed with Status Code:", response.status);
      dispatch({
        type: TRAYS_SOLD_FETCH_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching dashboaard data:", error);
    dispatch({
      type: TRAYS_SOLD_FETCH_FAIL,
    });
  }
};

export const fetchOverPaid = () => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/total-overpaid/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const overpaidData = response.data;

      console.log("overpaid Data:", overpaidData); // Log the retrieved data

      dispatch({
        type: OVERPAID_FETCH_SUCCESS,
        payload: overpaidData,
      });

      return overpaidData;
    } else {
      console.error("API Request Failed with Status Code:", response.status);
      dispatch({
        type: OVERPAID_FETCH_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching dashboaard data:", error);
    dispatch({
      type: OVERPAID_FETCH_FAIL,
    });
  }
};

export const fetchDashboard = () => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/dashboard/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const dashboaardData = response.data;

      console.log("dashboaard Data:", dashboaardData); // Log the retrieved data

      dispatch({
        type: DASHBOARD_FETCH_SUCCESS,
        payload: dashboaardData,
      });

      return dashboaardData;
    } else {
      console.error("API Request Failed with Status Code:", response.status);
      dispatch({
        type: DASHBOARD_FETCH_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching dashboaard data:", error);
    dispatch({
      type: DASHBOARD_FETCH_FAIL,
    });
  }
};

export const fetchDailyData = () => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/daily_chart/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const dailychartData = response.data;

      console.log("dailychart Data:", dailychartData); // Log the retrieved data

      dispatch({
        type: DAILYCHART_FETCH_SUCCESS,
        payload: dailychartData,
      });

      return dailychartData;
    } else {
      console.error("API Request Failed with Status Code:", response.status);
      dispatch({
        type: DAILYCHART_FETCH_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching dashboaard data:", error);
    dispatch({
      type: DAILYCHART_FETCH_FAIL,
    });
  }
};

export const fetchMonthlyData = () => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/monthly_chart/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const monthlychartData = response.data;

      console.log("monthlychart Data:", monthlychartData); // Log the retrieved data

      dispatch({
        type: MONTHLYCHART_FETCH_SUCCESS,
        payload: monthlychartData,
      });

      return monthlychartData;
    } else {
      console.error("API Request Failed with Status Code:", response.status);
      dispatch({
        type: MONTHLYCHART_FETCH_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching dashboaard data:", error);
    dispatch({
      type: MONTHLYCHART_FETCH_FAIL,
    });
  }
};

export const fetchYearlyData = () => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/yearly_chart/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const yearlychartData = response.data;

      console.log("yearlychart Data:", yearlychartData); // Log the retrieved data

      dispatch({
        type: YEARLYCHART_FETCH_SUCCESS,
        payload: yearlychartData,
      });

      return yearlychartData;
    } else {
      console.error("API Request Failed with Status Code:", response.status);
      dispatch({
        type: YEARLYCHART_FETCH_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching dashboaard data:", error);
    dispatch({
      type: YEARLYCHART_FETCH_FAIL,
    });
  }
};

// API handler for the customer

const fetchCustomerByPhone = async (phone, token) => {
    try {
      // Make an HTTP GET request to your API endpoint
      const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/customerbyname/${phone}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
};

export const searchCustomer = (phone) => async (dispatch) => {
  try {
    if (localStorage.getItem('access')) {
      const token = localStorage.getItem('access');
      const response = await fetchCustomerByPhone(phone, token);

      if (response && response.status === 200) {
        const customerData = await response.json();
        console.log("Customer Data:", customerData); // Log the customer data
        dispatch({
          type: CUSTOMER_SEARCH_SUCCESS,
          payload: customerData
        })
        return customerData; // Return the data received from the API
      } else {
        console.log("API Error:", response); // Log the API response in case of an error
        dispatch({
          type: CUSTOMER_SEARCH_SUCCESS,
          payload: response
        })
        return response; // Return the error response for debugging
      }
    } else {
      // Handle unauthenticated user
      dispatch({
        type: CUSTOMER_SEARCH_FAIL,
      })
      return [];
    }
  } catch (error) {
    console.error("Error fetching customer data:", error); // Log any network errors
    return [];
  }
};

const fetchCustomerByName = async (name, token) => {
  try {
    // Make an HTTP GET request to your API endpoint
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/customername/${name}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};
  
export const searchCustomerName = (name) => async (dispatch) => {
    try {
      if (localStorage.getItem('access')) {
        const token = localStorage.getItem('access');
        const response = await fetchCustomerByName(name, token);
  
        if (response && response.status === 200) {
          const customerData = await response.json();
          console.log("Customer Data:", customerData); // Log the customer data
          dispatch({
            type: CUSTOMER_SEARCH_SUCCESS,
            payload: customerData
          })
          return customerData; // Return the data received from the API
        } else {
          console.log("API Error:", response); // Log the API response in case of an error
          dispatch({
            type: CUSTOMER_SEARCH_SUCCESS,
            payload: response
          })
          return response; // Return the error response for debugging
        }
      } else {
        // Handle unauthenticated user
        dispatch({
          type: CUSTOMER_SEARCH_FAIL,
        })
        return [];
      }
    } catch (error) {
      console.error("Error fetching customer data:", error); // Log any network errors
      return [];
    }
};

export const fetchCustomerOnly = () => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    // Make an HTTP GET request to fetch batch data using the environment variable
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/customer-only/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const customerData = response.data;
      dispatch({
        type: CUSTOMER_ONLY_FETCH_SUCCESS,
        payload: customerData,
      });
      return customerData;
    } else {
      dispatch({
        type: CUSTOMER_ONLY_FETCH_FAIL,
      });
    }
  } catch (error) {
    console.error("Error ONLY_fetching CUSTOMER data:", error);
    dispatch({
      type: CUSTOMER_ONLY_FETCH_FAIL,
    });
  }
};

export const fetchAllCustomer = () => async (dispatch, getState) => {
    const { access } = getState().auth;
  
    try {
      // Make an HTTP GET request to fetch customer data using the environment variable
      const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/customer/`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
  
      if (response.status === 200) {
        const customerData = response.data;
        dispatch({
          type: CUSTOMER_FETCH_ALL_SUCCESS,
          payload: customerData,
        });
      } else {
        dispatch({
          type: CUSTOMER_FETCH_ALL_FAIL,
        });
      }
    } catch (error) {
      console.error("Error fetching customer data:", error);
      dispatch({
        type: CUSTOMER_FETCH_ALL_FAIL,
      });
    }
};

export const deleteCustomer = (id) => async (dispatch, getState) => {
    const { access } = getState().auth;

    try {
        const response = await Axios.delete(`${process.env.REACT_APP_API_URL}/api/customer/${id}/`, {
            headers: {
                Authorization: `Bearer ${access}`,
            },
        });

        if (response.status === 200) {
            // Dispatch a success action if the delete was successful
            dispatch({ type: CUSTOMER_DELETE_SUCCESS });

            // Dispatch an action to update the customer list
            dispatch({ type: CUSTOMER_UPDATE_LIST, payload: id }); // Send the deleted customer ID
        } else {
            // Dispatch a failure action if the delete failed
            dispatch({ type: CUSTOMER_DELETE_FAIL });
        }
    } catch (error) {
        console.log(error);
        dispatch({ type: CUSTOMER_DELETE_FAIL });
    }
};

export const fetchCustomerDetails = (id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/customer/${id}/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });
    if (response.status === 200) {
      const customerData = response.data; // Access data from the "data" key
      dispatch({
        type: CUSTOMER_FETCH_DETAILS_SUCCESS,
        payload: customerData,
      });
      return customerData;
    } else {
      dispatch({
        type: CUSTOMER_FETCH_DETAILS_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching customer data:", error);
    dispatch({
      type: CUSTOMER_FETCH_DETAILS_FAIL,
    });
  }
};
  
export const saveCustomer = (name, phone, secondary_phone, town) => async (dispatch, getState) => {
  const { access } = getState().auth;

  const config = {
      headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${access}`,
      },
      method: 'POST',
      body: JSON.stringify({ name, phone, secondary_phone, town })
  };

  try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/customer/`, config);

      if (res.ok) {
          const data = await res.json();
          dispatch({
            type: SAVE_CUSTOMER_SUCCESS,
            payload: data,
          });
          return data;
      } else {
          const error = await res.json();
          dispatch({
            type: SAVE_CUSTOMER_FAIL,
            payload: error,
          });
          return { success: false, error };
      }
  } catch (error) { 
    dispatch({
      type: SAVE_CUSTOMER_FAIL,
    });
      return { success: false, error: 'Network error' };
  }
}

export const editCustomer = (name, phone, secondary_phone, town, id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${access}`,
    },
    method: 'PUT',
    body: JSON.stringify({ name, phone, secondary_phone, town}),
  };

  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/customer/${id}/`, config);

    if (res.ok) {
      const data = await res.json();
      dispatch({
        type: EDIT_CUSTOMER_SUCCESS,
        payload: data,
      });
      return data;
    } else {
      const error = await res.json();
      dispatch({
        type: EDIT_CUSTOMER_FAIL,
        payload: error,
      });
      return { success: false, error };
    }
  } catch (error) {
    dispatch({
      type: EDIT_CUSTOMER_FAIL, // Change this to the correct action type
    });
    return { success: false, error: 'Network error' };
  }
};

// Api Handler for Orders

export const fetchAllOrders = () => async (dispatch, getState) => {
    const { access } = getState().auth;
  
    try {
      // Make an HTTP GET request to fetch orders data using the environment variable
      const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/orders/`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
  
      if (response.status === 200) {
        const ordersData = response.data;
        dispatch({
          type: ORDERS_FETCH_ALL_SUCCESS,
          payload: ordersData,
        });
      } else {
        dispatch({
          type: ORDERS_FETCH_ALL_FAIL,
        });
      }
    } catch (error) {
      console.error("Error fetching orders data:", error);
      dispatch({
        type: ORDERS_FETCH_ALL_FAIL,
      });
    }
};

export const deleteOrder = (id) => async (dispatch, getState) => {
    const { access } = getState().auth;

    try {
        const response = await Axios.delete(`${process.env.REACT_APP_API_URL}/api/orders/${id}/`, {
            headers: {
                Authorization: `Bearer ${access}`,
            },
        });

        if (response.status === 200) {
            // Dispatch a success action if the delete was successful
            dispatch({ type: ORDER_DELETE_SUCCESS });

            // Dispatch an action to update the customer list
            dispatch({ type: ORDER_UPDATE_LIST, payload: id }); // Send the deleted ORDER ID
        } else {
            // Dispatch a failure action if the delete failed
            dispatch({ type: ORDER_DELETE_FAIL });
        }
    } catch (error) {
        console.log(error);
        dispatch({ type: ORDER_DELETE_FAIL });
    }
};

export const fetchOrdersDetails = (id) => async (dispatch, getState) => {
    const { access } = getState().auth;
  
    try {
      const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/orders/${id}/`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
  
      if (response.status === 200) {
        const ordersData = response.data.data; // Access data from the "data" key
        dispatch({
          type: ORDERS_FETCH_DETAILS_SUCCESS,
          payload: ordersData,
        });
        return ordersData
      } else {
        dispatch({
          type: ORDERS_FETCH_DETAILS_FAIL,
        });
      }
    } catch (error) {
      console.error("Error fetching orders data:", error);
      dispatch({
        type: ORDERS_FETCH_DETAILS_FAIL,
      });
    }
};

const fetchOrderById = async (id, token) => {
  try {
    // Make an HTTP GET request to your API endpoint
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/ordername/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};
  
export const searchOrder = (id) => async (dispatch) => {
    try {
      if (localStorage.getItem('access')) {
        const token = localStorage.getItem('access');
        const response = await fetchOrderById(id, token);
  
        if (response && response.status === 200) {
          const ordersData = await response.json();
          console.log("Customer Data:", ordersData); // Log the customer data
          dispatch({
            type: ORDER_SEARCH_SUCCESS,
            payload: ordersData
          })
          return ordersData; // Return the data received from the API
        } else {
          console.log("API Error:", response); // Log the API response in case of an error
          dispatch({
            type: ORDER_SEARCH_SUCCESS,
            payload: response
          })
          return response; // Return the error response for debugging
        }
      } else {
        // Handle unauthenticated user
        dispatch({
          type: ORDER_SEARCH_FAIL,
        })
        return [];
      }
    } catch (error) {
      console.error("Error fetching customer data:", error); // Log any network errors
      return [];
    }
};

export const saveOrder = (customer_name, phone, customer_id, town, trays, discount, vat, price, amount, order_date) => async (dispatch, getState) => {
  const { access } = getState().auth;

  const config = {
      headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${access}`,
      },
      method: 'POST',
      body: JSON.stringify({ customer_name, phone, customer_id, town, trays, discount, vat, price, amount, order_date })
  };

  try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/orders/`, config);

      if (res.ok) {
          const data = await res.json();
          dispatch({
            type: SAVE_ORDERS_SUCCESS,
            payload: data
          });
          return { success: true, data };
      } else {
          const error = await res.json();
          dispatch({
            type: SAVE_ORDERS_FAIL,
            payload: error
          });
          return { success: false, error };
      }
  } catch (error) {
      return { success: false, error: 'Network error' };
  }
}  

export const editOrder = (customer_name, phone, customer_id, town, trays, discount, vat, price, amount, order_date, id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${access}`,
    },
    method: 'PUT',
    body: JSON.stringify({ customer_name, phone, customer_id, town, trays, discount, vat, price, amount, order_date}),
  };

  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/orders/${id}/`, config);

    if (res.ok) {
      const data = await res.json();
      dispatch({
        type: EDIT_ORDERS_SUCCESS,
        payload: data,
      });
      return data;
    } else {
      const error = await res.json();
      dispatch({
        type: EDIT_ORDERS_FAIL,
        payload: error,
      });
      return { success: false, error };
    }
  } catch (error) {
    dispatch({
      type: EDIT_ORDERS_FAIL, // Change this to the correct action type
    });
    return { success: false, error: 'Network error' };
  }
};

// Api Handler for Invoice

export const fetchAllInvoice = () => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    // Make an HTTP GET request to fetch orders data using the environment variable
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/invoice/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const invoiceData = response.data;
      dispatch({
        type: INVOICE_FETCH_ALL_SUCCESS,
        payload: invoiceData,
      });
    } else {
      dispatch({
        type: INVOICE_FETCH_ALL_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching INVOICE data:", error);
    dispatch({
      type: INVOICE_FETCH_ALL_FAIL,
    });
  }
};

export const deleteInvoice = (id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
      const response = await Axios.delete(`${process.env.REACT_APP_API_URL}/api/invoice/${id}/`, {
          headers: {
              Authorization: `Bearer ${access}`,
          },
      });

      if (response.status === 200) {
          // Dispatch a success action if the delete was successful
          dispatch({ type: INVOICE_DELETE_SUCCESS });

          // Dispatch an action to update the customer list
          dispatch({ type: INVOICE_UPDATE_LIST, payload: id }); // Send the deleted INVOICE ID
      } else {
          // Dispatch a failure action if the delete failed
          dispatch({ type: INVOICE_DELETE_FAIL });
      }
  } catch (error) {
      console.log(error);
      dispatch({ type: INVOICE_DELETE_FAIL });
  }
};

export const fetchInvoiceDetails = (id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/invoice/${id}/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const invoiceData = response.data.data; // Access data from the "data" key
      dispatch({
        type: INVOICE_FETCH_DETAILS_SUCCESS,
        payload: invoiceData,
      });
      return invoiceData
    } else {
      dispatch({
        type: INVOICE_FETCH_DETAILS_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching INVOICE data:", error);
    dispatch({
      type: INVOICE_FETCH_DETAILS_FAIL,
    });
  }
};

export const saveInvoice = (customer_id, product, invoice_details) => async (dispatch, getState) => {
  const { access } = getState().auth;

  const config = {
      headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${access}`,
      },
      method: 'POST',
      body: JSON.stringify({ customer_id, product, invoice_details })
  };

  try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/invoice/`, config);

      if (res.ok) {
          const data = await res.json();
          dispatch({
            type: SAVE_INVOICE_SUCCESS,
            payload: data
          });
          return { success: true, data };
      } else {
          const error = await res.json();
          dispatch({
            type: SAVE_INVOICE_FAIL,
            payload: error
          });
          return { success: false, error };
      }
  } catch (error) {
      return { success: false, error: 'Network error' };
  }
}  

// Api Handler for Payments

export const fetchAllPayments = () => async (dispatch, getState) => {
    const { access } = getState().auth;
  
    try {
      // Make an HTTP GET request to fetch orders data using the environment variable
      const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/payments/`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
  
      if (response.status === 200) {
        const paymentsData = response.data;
        dispatch({
          type: PAYMENTS_FETCH_ALL_SUCCESS,
          payload: paymentsData,
        });
      } else {
        dispatch({
          type: PAYMENTS_FETCH_ALL_FAIL,
        });
      }
    } catch (error) {
      console.error("Error fetching payment data:", error);
      dispatch({
        type: PAYMENTS_FETCH_ALL_FAIL,
      });
    }
};

export const deletePayment = (id) => async (dispatch, getState) => {
    const { access } = getState().auth;

    try {
        const response = await Axios.delete(`${process.env.REACT_APP_API_URL}/api/payments/${id}/`, {
            headers: {
                Authorization: `Bearer ${access}`,
            },
        });

        if (response.status === 200) {
            // Dispatch a success action if the delete was successful
            dispatch({ type: PAYMENT_DELETE_SUCCESS });

            // Dispatch an action to update the customer list
            dispatch({ type: PAYMENT_UPDATE_LIST, payload: id }); // Send the deleted PAYMENT ID
        } else {
            // Dispatch a failure action if the delete failed
            dispatch({ type: PAYMENT_DELETE_FAIL });
        }
    } catch (error) {
        console.log(error);
        dispatch({ type: PAYMENT_DELETE_FAIL });
    }
};

export const fetchPaymentDetails = (id) => async (dispatch, getState) => {
    const { access } = getState().auth;
  
    try {
      const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/payments/${id}/`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      });
  
      if (response.status === 200) {
        const paymentsData = response.data.data; // Access data from the "data" key
        dispatch({
          type: PAYMENTS_FETCH_DETAILS_SUCCESS,
          payload: paymentsData,
        });
        return paymentsData
      } else {
        dispatch({
          type: PAYMENTS_FETCH_DETAILS_FAIL,
        });
      }
    } catch (error) {
      console.error("Error fetching orders data:", error);
      dispatch({
        type: PAYMENTS_FETCH_DETAILS_FAIL,
      });
    }
};  

export const savePayment = (orders_id, paying_number, amount, payment_mode, payment, customer_id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  const config = {
      headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${access}`,
      },
      method: 'POST',
      body: JSON.stringify({ orders_id, paying_number, amount, payment_mode, payment, customer_id })
  };

  try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/payments/`, config);

      if (res.ok) {
          const data = await res.json();
          dispatch({
            type: SAVE_PAYMENTS_SUCCESS,
            payload: data
          });
          return { success: true, data };
      } else {
          const error = await res.json();
          dispatch({
            type: SAVE_PAYMENTS_FAIL,
            payload: error
          });
          return { success: false, error };
      }
  } catch (error) {
      return { success: false, error: 'Network error' };
  }
}   

export const editPayment = (orders_id, paying_number, amount, payment_mode, payment, customer_id, id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${access}`,
    },
    method: 'PUT',
    body: JSON.stringify({ orders_id, paying_number, amount, payment_mode, payment, customer_id }),
  };

  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/payments/${id}/`, config);

    if (res.ok) {
      const data = await res.json();
      dispatch({
        type: EDIT_PAYMENTS_SUCCESS,
        payload: data,
      });
      return data;
    } else {
      const error = await res.json();
      dispatch({
        type: EDIT_PAYMENTS_FAIL,
        payload: error,
      });
      return { success: false, error };
    }
  } catch (error) {
    dispatch({
      type: EDIT_PAYMENTS_FAIL, // Change this to the correct action type
    });
    return { success: false, error: 'Network error' };
  }
};

// API Handler for Chicken Batch

export const fetchAllBatch = () => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    // Make an HTTP GET request to fetch batch data using the environment variable
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/batch/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const batchData = response.data;
      dispatch({
        type: BATCH_FETCH_SUCCESS,
        payload: batchData,
      });
    } else {
      dispatch({
        type: BATCH_FETCH_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching batch data:", error);
    dispatch({
      type: BATCH_FETCH_FAIL,
    });
  }
};

export const fetchBatchOnly = () => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    // Make an HTTP GET request to fetch batch data using the environment variable
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/batchonly/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const batchData = response.data;
      dispatch({
        type: BATCH_ONLY_FETCH_SUCCESS,
        payload: batchData,
      });
      return batchData;
    } else {
      dispatch({
        type: BATCH_ONLY_FETCH_FAIL,
      });
    }
  } catch (error) {
    console.error("Error ONLY_fetching batch data:", error);
    dispatch({
      type: BATCH_ONLY_FETCH_FAIL,
    });
  }
};

export const saveBatch = (breed, chicken_age, chicken_count, is_vaccinated) => async (dispatch, getState) => {
  const { access } = getState().auth;

  const config = {
      headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${access}`,
      },
      method: 'POST',
      body: JSON.stringify({ breed, chicken_age, chicken_count, is_vaccinated })
  };

  try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/batch/`, config);

      if (res.ok) {
          const data = await res.json();
          dispatch({
            type: SAVE_BATCH_SUCCESS,
            payload: data
          });
          return data;
      } else {
          const error = await res.json();
          dispatch({
            type: SAVE_BATCH_FAIL,
            payload: error
          });
          return { success: false, error };
      }
  } catch (error) {
      return { success: false, error: 'Network error' };
  }
}

export const fetchBatchDetails = (id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/batch/${id}/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const batchData = response.data; // Access data from the "data" key
      dispatch({
        type: BATCH_FETCH_DETAILS_SUCCESS,
        payload: batchData,
      });
      return batchData
    } else {
      dispatch({
        type: BATCH_FETCH_DETAILS_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching batch data:", error);
    dispatch({
      type: BATCH_FETCH_DETAILS_FAIL,
    });
  }
}; 

export const editBatch = (selectedBatch) => async (dispatch, getState) => {
  const { access } = getState().auth;
  const { breed, chicken_age, chicken_count, is_vaccinated, batch_no, id } = selectedBatch;

  const config = {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({ breed, chicken_age, chicken_count, is_vaccinated, batch_no }),
  };

  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/batch/${id}/`, config);

    if (res.ok) {
      const data = await res.json();
      dispatch({
        type: EDIT_BATCH_SUCCESS,
        payload: data,
      });
      return {success: true, data};
    } else {
      const error = await res.json();
      dispatch({
        type: EDIT_BATCH_FAIL,
        payload: error,
      });
      return { success: false, error };
    }
  } catch (error) {
    dispatch({
      type: EDIT_BATCH_FAIL, // Change this to the correct action type
    });
    return { success: false, error: 'Network error' };
  }
};

export const deleteBatch = (id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
      const response = await Axios.delete(`${process.env.REACT_APP_API_URL}/api/batch/${id}/`, {
          headers: {
              Authorization: `Bearer ${access}`,
          },
      });

      if (response.status === 200) {
          // Dispatch a success action if the delete was successful
          dispatch({ type: DELETE_BATCH_SUCCESS });

          // Dispatch an action to update the customer list
          dispatch({ type: BATCH_UPDATE_LIST, payload: id }); // Send the deleted PAYMENT ID
      } else {
          // Dispatch a failure action if the delete failed
          dispatch({ type: DELETE_BATCH_FAIL });
      }
  } catch (error) {
      console.log(error);
      dispatch({ type: DELETE_BATCH_FAIL });
  }
};

// API Handler for Eggs Collection

export const fetchAllEggs = () => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    // Make an HTTP GET request to fetch batch data using the environment variable
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/eggs/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const eggsData = response.data;
      dispatch({
        type: EGGS_FETCH_SUCCESS,
        payload: eggsData,
      });
    } else {
      dispatch({
        type: EGGS_FETCH_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching EGGS data:", error);
    dispatch({
      type: EGGS_FETCH_FAIL,
    });
  }
};

export const saveEggs = (batch_id, tray_count, broken_eggs) => async (dispatch, getState) => {
  const { access } = getState().auth;

  const config = {
      headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${access}`,
      },
      method: 'POST',
      body: JSON.stringify({ batch_id, tray_count, broken_eggs })
  };

  try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/eggs/`, config);

      if (res.ok) {
          const data = await res.json();
          dispatch({
            type: SAVE_EGGS_SUCCESS,
            payload: data
          });
          return data;
      } else {
          const error = await res.json();
          dispatch({
            type: SAVE_EGGS_FAIL,
            payload: error
          });
          return { success: false, error };
      }
  } catch (error) {
      return { success: false, error: 'Network error' };
  }
}

export const fetchEggsDetails = (id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/eggs/${id}/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const eggsData = response.data.data; // Access data from the "data" key
      dispatch({
        type: EGGS_FETCH_DETAILS_SUCCESS,
        payload: eggsData,
      });
      return eggsData
    } else {
      dispatch({
        type: EGGS_FETCH_DETAILS_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching EGGS data:", error);
    dispatch({
      type: EGGS_FETCH_DETAILS_FAIL,
    });
  }
}; 

export const editEggs = (selectedBatch) => async (dispatch, getState) => {
  const { access } = getState().auth;
  const { batch_id, tray_count, broken_eggs, id } = selectedBatch;

  const config = {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({ batch_id, tray_count, broken_eggs }),
  };

  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/eggs/${id}/`, config);

    if (res.ok) {
      const data = await res.json();
      dispatch({
        type: EDIT_EGGS_SUCCESS,
        payload: data,
      });
      return {success: true, data};
    } else {
      const error = await res.json();
      dispatch({
        type: EDIT_EGGS_FAIL,
        payload: error,
      });
      return { success: false, error };
    }
  } catch (error) {
    dispatch({
      type: EDIT_EGGS_FAIL, // Change this to the correct action type
    });
    return { success: false, error: 'Network error' };
  }
};

export const deleteEggs = (id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
      const response = await Axios.delete(`${process.env.REACT_APP_API_URL}/api/eggs/${id}/`, {
          headers: {
              Authorization: `Bearer ${access}`,
          },
      });

      if (response.status === 200) {
          // Dispatch a success action if the delete was successful
          dispatch({ type: DELETE_EGGS_SUCCESS });

          // Dispatch an action to update the customer list
          dispatch({ type: EGGS_UPDATE_LIST, payload: id }); // Send the deleted PAYMENT ID
      } else {
          // Dispatch a failure action if the delete failed
          dispatch({ type: DELETE_EGGS_FAIL });
      }
  } catch (error) {
      console.log(error);
      dispatch({ type: DELETE_EGGS_FAIL });
  }
};

// API Handler for Food Intakecd

export const fetchAllFood= () => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    // Make an HTTP GET request to fetch batch data using the environment variable
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/food-intake/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const foodData = response.data;
      dispatch({
        type: FOOD_FETCH_SUCCESS,
        payload: foodData,
      });
    } else {
      dispatch({
        type: FOOD_FETCH_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching FOOD data:", error);
    dispatch({
      type: FOOD_FETCH_FAIL,
    });
  }
};

export const saveFood = (batch_id, bag, date_consumed) => async (dispatch, getState) => {
  const { access } = getState().auth;

  const config = {
      headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${access}`,
      },
      method: 'POST',
      body: JSON.stringify({ batch_id, bag, date_consumed })
  };

  try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/food-intake/`, config);

      if (res.ok) {
          const data = await res.json();
          dispatch({
            type: SAVE_FOOD_SUCCESS,
            payload: data
          });
          return data;
      } else {
          const error = await res.json();
          dispatch({
            type: SAVE_FOOD_FAIL,
            payload: error
          });
          return { success: false, error };
      }
  } catch (error) {
      return { success: false, error: 'Network error' };
  }
}

export const fetchFoodDetails = (id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/food-intake/${id}/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const foodData = response.data.data; // Access data from the "data" key
      dispatch({
        type: FOOD_FETCH_DETAILS_SUCCESS,
        payload: foodData,
      });
      return foodData
    } else {
      dispatch({
        type: FOOD_FETCH_DETAILS_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching FOOD data:", error);
    dispatch({
      type: FOOD_FETCH_DETAILS_FAIL,
    });
  }
}; 

export const editFood = (selectedBatch) => async (dispatch, getState) => {
  const { access } = getState().auth;
  const { batch_id, bag, date_consumed, id } = selectedBatch;

  const config = {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({ batch_id, bag, date_consumed }),
  };

  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/food-intake/${id}/`, config);

    if (res.ok) {
      const data = await res.json();
      dispatch({
        type: EDIT_FOOD_SUCCESS,
        payload: data,
      });
      return {success: true, data};
    } else {
      const error = await res.json();
      dispatch({
        type: EDIT_FOOD_FAIL,
        payload: error,
      });
      return { success: false, error };
    }
  } catch (error) {
    dispatch({
      type: EDIT_FOOD_FAIL, // Change this to the correct action type
    });
    return { success: false, error: 'Network error' };
  }
};

export const deleteFood = (id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
      const response = await Axios.delete(`${process.env.REACT_APP_API_URL}/api/food-intake/${id}/`, {
          headers: {
              Authorization: `Bearer ${access}`,
          },
      });

      if (response.status === 200) {
          // Dispatch a success action if the delete was successful
          dispatch({ type: DELETE_FOOD_SUCCESS });

          // Dispatch an action to update the customer list
          dispatch({ type: FOOD_UPDATE_LIST, payload: id }); // Send the deleted PAYMENT ID
      } else {
          // Dispatch a failure action if the delete failed
          dispatch({ type: DELETE_FOOD_FAIL });
      }
  } catch (error) {
      console.log(error);
      dispatch({ type: DELETE_FOOD_FAIL });
  }
};

// API Handler for Food Intakecd

export const fetchAllMortality= () => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    // Make an HTTP GET request to fetch batch data using the environment variable
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/mortality/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const mortalityData = response.data;
      dispatch({
        type: MORTALITY_FETCH_SUCCESS,
        payload: mortalityData,
      });
    } else {
      dispatch({
        type: MORTALITY_FETCH_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching MORTALITY data:", error);
    dispatch({
      type: MORTALITY_FETCH_FAIL,
    });
  }
};

export const saveMortality = (batch_id, death_date, number_of_deaths) => async (dispatch, getState) => {
  const { access } = getState().auth;

  const config = {
      headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${access}`,
      },
      method: 'POST',
      body: JSON.stringify({ batch_id, death_date, number_of_deaths })
  };

  try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/mortality/`, config);

      if (res.ok) {
          const data = await res.json();
          dispatch({
            type: SAVE_MORTALITY_SUCCESS,
            payload: data
          });
          return data;
      } else {
          const error = await res.json();
          dispatch({
            type: SAVE_MORTALITY_FAIL,
            payload: error
          });
          return { success: false, error };
      }
  } catch (error) {
      return { success: false, error: 'Network error' };
  }
}

export const fetchMortalityDetails = (id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/mortality/${id}/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const mortalityData = response.data.data; // Access data from the "data" key
      dispatch({
        type: MORTALITY_FETCH_DETAILS_SUCCESS,
        payload: mortalityData,
      });
      return mortalityData
    } else {
      dispatch({
        type: MORTALITY_FETCH_DETAILS_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching MORTALITY data:", error);
    dispatch({
      type: MORTALITY_FETCH_DETAILS_FAIL,
    });
  }
}; 

export const editMortality = (selectedBatch) => async (dispatch, getState) => {
  const { access } = getState().auth;
  const { batch_id, death_date, number_of_deaths, id } = selectedBatch;

  const config = {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({ batch_id, death_date, number_of_deaths }),
  };

  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/mortality/${id}/`, config);

    if (res.ok) {
      const data = await res.json();
      dispatch({
        type: EDIT_MORTALITY_SUCCESS,
        payload: data,
      });
      return {success: true, data};
    } else {
      const error = await res.json();
      dispatch({
        type: EDIT_MORTALITY_FAIL,
        payload: error,
      });
      return { success: false, error };
    }
  } catch (error) {
    dispatch({
      type: EDIT_MORTALITY_FAIL, // Change this to the correct action type
    });
    return { success: false, error: 'Network error' };
  }
}; 

export const deleteMortality = (id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
      const response = await Axios.delete(`${process.env.REACT_APP_API_URL}/api/mortality/${id}/`, {
          headers: {
              Authorization: `Bearer ${access}`,
          },
      });

      if (response.status === 200) {
          // Dispatch a success action if the delete was successful
          dispatch({ type: DELETE_MORTALITY_SUCCESS });

          // Dispatch an action to update the customer list
          dispatch({ type: MORTALITY_UPDATE_LIST, payload: id }); // Send the deleted PAYMENT ID
      } else {
          // Dispatch a failure action if the delete failed
          dispatch({ type: DELETE_MORTALITY_FAIL });
      }
  } catch (error) {
      console.log(error);
      dispatch({ type: DELETE_MORTALITY_FAIL });
  }
};

// API Handler for Medicine & Vaccination

export const fetchAllMeds= () => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    // Make an HTTP GET request to fetch batch data using the environment variable
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/meds/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const medsData = response.data;
      dispatch({
        type: MEDS_FETCH_SUCCESS,
        payload: medsData,
      });
    } else {
      dispatch({
        type: MEDS_FETCH_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching MEDS data:", error);
    dispatch({
      type: MEDS_FETCH_FAIL,
    });
  }
};

export const saveMeds = (batch_id, meds_type, meds_name, date_administered) => async (dispatch, getState) => {
  const { access } = getState().auth;

  const config = {
      headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${access}`,
      },
      method: 'POST',
      body: JSON.stringify({ batch_id, meds_type, meds_name, date_administered })
  };

  try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/meds/`, config);

      if (res.ok) {
          const data = await res.json();
          dispatch({
            type: SAVE_MEDS_SUCCESS,
            payload: data
          });
          return data;
      } else {
          const error = await res.json();
          dispatch({
            type: SAVE_MEDS_FAIL,
            payload: error
          });
          return { success: false, error };
      }
  } catch (error) {
      return { success: false, error: 'Network error' };
  }
}

export const fetchMedsDetails = (id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/meds/${id}/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const medsData = response.data.data; // Access data from the "data" key
      dispatch({
        type: MEDS_FETCH_DETAILS_SUCCESS,
        payload: medsData,
      });
      return medsData
    } else {
      dispatch({
        type: MEDS_FETCH_DETAILS_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching MEDS data:", error);
    dispatch({
      type: MEDS_FETCH_DETAILS_FAIL,
    });
  }
}; 

export const editMeds = (selectedBatch) => async (dispatch, getState) => {
  const { access } = getState().auth;
  const { batch_id, meds_type, meds_name, date_administered, id } = selectedBatch;

  const config = {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({ batch_id, meds_type, meds_name, date_administered }),
  };

  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/meds/${id}/`, config);

    if (res.ok) {
      const data = await res.json();
      dispatch({
        type: EDIT_MEDS_SUCCESS,
        payload: data,
      });
      return {success: true, data};
    } else {
      const error = await res.json();
      dispatch({
        type: EDIT_MEDS_FAIL,
        payload: error,
      });
      return { success: false, error };
    }
  } catch (error) {
    dispatch({
      type: EDIT_MEDS_FAIL, // Change this to the correct action type
    });
    return { success: false, error: 'Network error' };
  }
}; 

export const deleteMeds = (id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
      const response = await Axios.delete(`${process.env.REACT_APP_API_URL}/api/meds/${id}/`, {
          headers: {
              Authorization: `Bearer ${access}`,
          },
      });

      if (response.status === 200) {
          // Dispatch a success action if the delete was successful
          dispatch({ type: DELETE_MEDS_SUCCESS });

          // Dispatch an action to update the customer list
          dispatch({ type: MEDS_UPDATE_LIST, payload: id }); // Send the deleted PAYMENT ID
      } else {
          // Dispatch a failure action if the delete failed
          dispatch({ type: DELETE_MEDS_FAIL });
      }
  } catch (error) {
      console.log(error);
      dispatch({ type: DELETE_MEDS_FAIL });
  }
};

// API Handler for Meat production

export const fetchAllMeat = () => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    // Make an HTTP GET request to fetch batch data using the environment variable
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/meat/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const meatData = response.data;
      dispatch({
        type: MEAT_FETCH_SUCCESS,
        payload: meatData,
      });
    } else {
      dispatch({
        type: MEAT_FETCH_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching MEAT data:", error);
    dispatch({
      type: MEAT_FETCH_FAIL,
    });
  }
};

export const saveMeat = (batch_id, customer_id, number_of_birds, price, vat, amount) => async (dispatch, getState) => {
  const { access } = getState().auth;

  const config = {
      headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${access}`,
      },
      method: 'POST',
      body: JSON.stringify({ batch_id, customer_id, number_of_birds, price, vat, amount })
  };

  try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/meat/`, config);

      if (res.ok) {
          const data = await res.json();
          dispatch({
            type: SAVE_MEAT_SUCCESS,
            payload: data
          });
          return data;
      } else {
          const error = await res.json();
          dispatch({
            type: SAVE_MEAT_FAIL,
            payload: error
          });
          return { success: false, error };
      }
  } catch (error) {
      return { success: false, error: 'Network error' };
  }
}

export const fetchMeatDetails = (id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/meat/${id}/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const meatData = response.data.data; // Access data from the "data" key
      dispatch({
        type: MEAT_FETCH_DETAILS_SUCCESS,
        payload: meatData,
      });
      return meatData
    } else {
      dispatch({
        type: MEAT_FETCH_DETAILS_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching MEAT data:", error);
    dispatch({
      type: MEAT_FETCH_DETAILS_FAIL,
    });
  }
}; 

export const editMeat = (selectedBatch) => async (dispatch, getState) => {
  const { access } = getState().auth;
  const { batch_id, customer_id, number_of_birds, price, vat, amount, id } = selectedBatch;

  const config = {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({ batch_id, customer_id, number_of_birds, price, vat, amount }),
  };

  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/meat/${id}/`, config);

    if (res.ok) {
      const data = await res.json();
      dispatch({
        type: EDIT_MEAT_SUCCESS,
        payload: data,
      });
      return {success: true, data};
    } else {
      const error = await res.json();
      dispatch({
        type: EDIT_MEAT_FAIL,
        payload: error,
      });
      return { success: false, error };
    }
  } catch (error) {
    dispatch({
      type: EDIT_MEAT_FAIL, // Change this to the correct action type
    });
    return { success: false, error: 'Network error' };
  }
}; 

export const deleteMeat = (id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
      const response = await Axios.delete(`${process.env.REACT_APP_API_URL}/api/meat/${id}/`, {
          headers: {
              Authorization: `Bearer ${access}`,
          },
      });

      if (response.status === 200) {
          // Dispatch a success action if the delete was successful
          dispatch({ type: DELETE_MEAT_SUCCESS });

          // Dispatch an action to update the customer list
          dispatch({ type: MEAT_UPDATE_LIST, payload: id }); // Send the deleted PAYMENT ID
      } else {
          // Dispatch a failure action if the delete failed
          dispatch({ type: DELETE_MEAT_FAIL });
      }
  } catch (error) {
      console.log(error);
      dispatch({ type: DELETE_MEAT_FAIL });
  }
};

// API Handler for Employee Management

export const fetchAllEmployee = () => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    // Make an HTTP GET request to fetch batch data using the environment variable
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/employee/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const employeeData = response.data;
      dispatch({
        type: EMPLOYEE_FETCH_SUCCESS,
        payload: employeeData,
      });
    } else {
      dispatch({
        type: EMPLOYEE_FETCH_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching EMPLOYEE data:", error);
    dispatch({
      type: EMPLOYEE_FETCH_FAIL,
    });
  }
};

export const fetchEmployeeOnly = () => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    // Make an HTTP GET request to fetch batch data using the environment variable
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/employeeonly/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const employeeData = response.data;
      dispatch({
        type: EMPLOYEE_ONLY_FETCH_SUCCESS,
        payload: employeeData,
      });
      return employeeData;
    } else {
      dispatch({
        type: EMPLOYEE_ONLY_FETCH_FAIL,
      });
    }
  } catch (error) {
    console.error("Error ONLY_fetching batch data:", error);
    dispatch({
      type: EMPLOYEE_ONLY_FETCH_FAIL,
    });
  }
};

export const saveEmployee = (first_name, last_name, gender, position, date_hired, contact_number, town) => async (dispatch, getState) => {
  const { access } = getState().auth;

  const config = {
      headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${access}`,
      },
      method: 'POST',
      body: JSON.stringify({ first_name, last_name, gender, position, date_hired, contact_number, town })
  };

  try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/employee/`, config);

      if (res.ok) {
          const data = await res.json();
          dispatch({
            type: SAVE_EMPLOYEE_SUCCESS,
            payload: data
          });
          return data;
      } else {
          const error = await res.json();
          dispatch({
            type: SAVE_EMPLOYEE_FAIL,
            payload: error
          });
          return { success: false, error };
      }
  } catch (error) {
      return { success: false, error: 'Network error' };
  }
}

export const fetchEmployeeDetails = (id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/employee/${id}/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const employeeData = response.data; // Access data from the "data" key
      dispatch({
        type: EMPLOYEE_FETCH_DETAILS_SUCCESS,
        payload: employeeData,
      });
      return employeeData
    } else {
      dispatch({
        type: EMPLOYEE_FETCH_DETAILS_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching EMPLOYEE data:", error);
    dispatch({
      type: EMPLOYEE_FETCH_DETAILS_FAIL,
    });
  }
}; 

export const editEmployee = (first_name, last_name, gender, position, date_hired, contact_number, town, id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${access}`,
    },
    method: 'PUT',
    body: JSON.stringify({ first_name, last_name, gender, position, date_hired, contact_number, town}),
  };

  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/employee/${id}/`, config);

    if (res.ok) {
      const data = await res.json();
      dispatch({
        type: EDIT_EMPLOYEE_SUCCESS,
        payload: data,
      });
      return data;
    } else {
      const error = await res.json();
      dispatch({
        type: EDIT_EMPLOYEE_FAIL,
        payload: error,
      });
      return { success: false, error };
    }
  } catch (error) {
    dispatch({
      type: EDIT_EMPLOYEE_FAIL, // Change this to the correct action type
    });
    return { success: false, error: 'Network error' };
  }
};

export const deleteEmployee = (id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
      const response = await Axios.delete(`${process.env.REACT_APP_API_URL}/api/employee/${id}/`, {
          headers: {
              Authorization: `Bearer ${access}`,
          },
      });

      if (response.status === 200) {
          // Dispatch a success action if the delete was successful
          dispatch({ type: DELETE_EMPLOYEE_SUCCESS });

          // Dispatch an action to update the customer list
          dispatch({ type: EMPLOYEE_UPDATE_LIST, payload: id }); // Send the deleted PAYMENT ID
      } else {
          // Dispatch a failure action if the delete failed
          dispatch({ type: DELETE_EMPLOYEE_FAIL });
      }
  } catch (error) {
      console.log(error);
      dispatch({ type: DELETE_EMPLOYEE_FAIL });
  }
};

// API Handler for Employee Payment Management

export const fetchAllEmployeePayment = () => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    // Make an HTTP GET request to fetch batch data using the environment variable
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/employee-payments/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const employeePaymentData = response.data;
      dispatch({
        type: EMPLOYEE_PAYMENT_FETCH_SUCCESS,
        payload: employeePaymentData,
      });
    } else {
      dispatch({
        type: EMPLOYEE_PAYMENT_FETCH_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching EMPLOYEE data:", error);
    dispatch({
      type: EMPLOYEE_PAYMENT_FETCH_FAIL,
    });
  }
};

export const saveEmployeePayment = (employee_id, payment_date, amount_paid) => async (dispatch, getState) => {
  const { access } = getState().auth;

  const config = {
      headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${access}`,
      },
      method: 'POST',
      body: JSON.stringify({ employee_id, payment_date, amount_paid })
  };

  try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/employee-payments/`, config);

      if (res.ok) {
          const data = await res.json();
          dispatch({
            type: SAVE_EMPLOYEE_PAYMENT_SUCCESS,
            payload: data
          });
          return data;
      } else {
          const error = await res.json();
          dispatch({
            type: SAVE_EMPLOYEE_PAYMENT_FAIL,
            payload: error
          });
          return { success: false, error };
      }
  } catch (error) {
      return { success: false, error: 'Network error' };
  }
}

export const fetchEmployeePaymentDetails = (id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/employee-payments/${id}/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const employeePaymentData = response.data; // Access data from the "data" key
      dispatch({
        type: EMPLOYEE_PAYMENT_FETCH_DETAILS_SUCCESS,
        payload: employeePaymentData,
      });
      return employeePaymentData
    } else {
      dispatch({
        type: EMPLOYEE_PAYMENT_FETCH_DETAILS_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching EMPLOYEE data:", error);
    dispatch({
      type: EMPLOYEE_PAYMENT_FETCH_DETAILS_FAIL,
    });
  }
}; 

export const editEmployeePayment = (selectedBatch) => async (dispatch, getState) => {
  const { access } = getState().auth;
  const { employee_id, payment_date, amount_paid, id } = selectedBatch;

  const config = {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${access}`,
    },
    method: 'PUT',
    body: JSON.stringify({ employee_id, payment_date, amount_paid}),
  };

  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/employee-payments/${id}/`, config);

    if (res.ok) {
      const data = await res.json();
      dispatch({
        type: EDIT_EMPLOYEE_PAYMENT_SUCCESS,
        payload: data,
      });
      return {success: true, data};
    } else {
      const error = await res.json();
      console.log(error);
      dispatch({
        type: EDIT_EMPLOYEE_PAYMENT_FAIL,
        payload: error,
      });
      return { success: false, error };
    }
  } catch (error) {
    dispatch({
      type: EDIT_EMPLOYEE_PAYMENT_FAIL, // Change this to the correct action type
    });
    return { success: false, error: 'Network error' };
  }
};

export const deleteEmployeePayment = (id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
      const response = await Axios.delete(`${process.env.REACT_APP_API_URL}/api/employee-payments/${id}/`, {
          headers: {
              Authorization: `Bearer ${access}`,
          },
      });

      if (response.status === 200) {
          // Dispatch a success action if the delete was successful
          dispatch({ type: DELETE_EMPLOYEE_PAYMENT_SUCCESS });

          // Dispatch an action to update the customer list
          dispatch({ type: EMPLOYEE_PAYMENT_UPDATE_LIST, payload: id }); // Send the deleted PAYMENT ID
      } else {
          // Dispatch a failure action if the delete failed
          dispatch({ type: DELETE_EMPLOYEE_PAYMENT_FAIL });
      }
  } catch (error) {
      console.log(error);
      dispatch({ type: DELETE_EMPLOYEE_PAYMENT_FAIL });
  }
};

// API Handler for Feed Cost

export const fetchAllFoodCost = () => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    // Make an HTTP GET request to fetch batch data using the environment variable
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/food-cost/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const eggsData = response.data;
      dispatch({
        type: FEED_COST_FETCH_SUCCESS,
        payload: eggsData,
      });
    } else {
      dispatch({
        type: FEED_COST_FETCH_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching EGGS data:", error);
    dispatch({
      type: FEED_COST_FETCH_FAIL,
    });
  }
};

export const saveFoodCost = (bags, cost_per_bag, total_cost) => async (dispatch, getState) => {
  const { access } = getState().auth;

  const config = {
      headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${access}`,
      },
      method: 'POST',
      body: JSON.stringify({ bags, cost_per_bag, total_cost })
  };

  try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/food-cost/`, config);

      if (res.ok) {
          const data = await res.json();
          dispatch({
            type: SAVE_FEED_COST_SUCCESS,
            payload: data
          });
          return data;
      } else {
          const error = await res.json();
          dispatch({
            type: SAVE_FEED_COST_FAIL,
            payload: error
          });
          return { success: false, error };
      }
  } catch (error) {
      return { success: false, error: 'Network error' };
  }
}

export const fetchFoodCostDetails = (id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/food-cost/${id}/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const eggsData = response.data.data; // Access data from the "data" key
      dispatch({
        type: FEED_COST_FETCH_DETAILS_SUCCESS,
        payload: eggsData,
      });
      return eggsData
    } else {
      dispatch({
        type: FEED_COST_FETCH_DETAILS_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching EGGS data:", error);
    dispatch({
      type: FEED_COST_FETCH_DETAILS_FAIL,
    });
  }
}; 

export const editFoodCost = (selectedBatch) => async (dispatch, getState) => {
  const { access } = getState().auth;
  const { bags, cost_per_bag, total_cost, id } = selectedBatch;

  const config = {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({ bags, cost_per_bag, total_cost }),
  };

  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/food-cost/${id}/`, config);

    if (res.ok) {
      const data = await res.json();
      dispatch({
        type: EDIT_FEED_COST_SUCCESS,
        payload: data,
      });
      return {success: true, data};
    } else {
      const error = await res.json();
      dispatch({
        type: EDIT_FEED_COST_FAIL,
        payload: error,
      });
      return { success: false, error };
    }
  } catch (error) {
    dispatch({
      type: EDIT_FEED_COST_FAIL, // Change this to the correct action type
    });
    return { success: false, error: 'Network error' };
  }
};

export const deleteFoodCost = (id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
      const response = await Axios.delete(`${process.env.REACT_APP_API_URL}/api/food-cost/${id}/`, {
          headers: {
              Authorization: `Bearer ${access}`,
          },
      });

      if (response.status === 200) {
          // Dispatch a success action if the delete was successful
          dispatch({ type: DELETE_FEED_COST_SUCCESS });

          // Dispatch an action to update the customer list
          dispatch({ type: FEED_COST_UPDATE_LIST, payload: id }); // Send the deleted PAYMENT ID
      } else {
          // Dispatch a failure action if the delete failed
          dispatch({ type: DELETE_FEED_COST_FAIL });
      }
  } catch (error) {
      console.log(error);
      dispatch({ type: DELETE_FEED_COST_FAIL });
  }
};

// API Handler for Meds Cost

export const fetchAllMedsCost = () => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    // Make an HTTP GET request to fetch batch data using the environment variable
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/meds-cost/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const eggsData = response.data;
      dispatch({
        type: MEDS_COST_FETCH_SUCCESS,
        payload: eggsData,
      });
    } else {
      dispatch({
        type: MEDS_COST_FETCH_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching EGGS data:", error);
    dispatch({
      type: MEDS_COST_FETCH_FAIL,
    });
  }
};

export const saveMedsCost = (meds_type, medicine_name, cost) => async (dispatch, getState) => {
  const { access } = getState().auth;

  const config = {
      headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${access}`,
      },
      method: 'POST',
      body: JSON.stringify({ meds_type, medicine_name, cost })
  };

  try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/meds-cost/`, config);

      if (res.ok) {
          const data = await res.json();
          dispatch({
            type: SAVE_MEDS_COST_SUCCESS,
            payload: data
          });
          return data;
      } else {
          const error = await res.json();
          dispatch({
            type: SAVE_MEDS_COST_FAIL,
            payload: error
          });
          return { success: false, error };
      }
  } catch (error) {
      return { success: false, error: 'Network error' };
  }
}

export const fetchMedsCostDetails = (id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/meds-cost/${id}/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const eggsData = response.data.data; // Access data from the "data" key
      dispatch({
        type: MEDS_COST_FETCH_DETAILS_SUCCESS,
        payload: eggsData,
      });
      return eggsData
    } else {
      dispatch({
        type: MEDS_COST_FETCH_DETAILS_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching EGGS data:", error);
    dispatch({
      type: MEDS_COST_FETCH_DETAILS_FAIL,
    });
  }
}; 

export const editMedsCost = (selectedBatch) => async (dispatch, getState) => {
  const { access } = getState().auth;
  const { meds_type, medicine_name, cost, id } = selectedBatch;

  const config = {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({ meds_type, medicine_name, cost }),
  };

  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/meds-cost/${id}/`, config);

    if (res.ok) {
      const data = await res.json();
      dispatch({
        type: EDIT_MEDS_COST_SUCCESS,
        payload: data,
      });
      return {success: true, data};
    } else {
      const error = await res.json();
      dispatch({
        type: EDIT_MEDS_COST_FAIL,
        payload: error,
      });
      return { success: false, error };
    }
  } catch (error) {
    dispatch({
      type: EDIT_MEDS_COST_FAIL, // Change this to the correct action type
    });
    return { success: false, error: 'Network error' };
  }
};

export const deleteMedsCost = (id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
      const response = await Axios.delete(`${process.env.REACT_APP_API_URL}/api/meds-cost/${id}/`, {
          headers: {
              Authorization: `Bearer ${access}`,
          },
      });

      if (response.status === 200) {
          // Dispatch a success action if the delete was successful
          dispatch({ type: DELETE_MEDS_COST_SUCCESS });

          // Dispatch an action to update the customer list
          dispatch({ type: MEDS_COST_UPDATE_LIST, payload: id }); // Send the deleted PAYMENT ID
      } else {
          // Dispatch a failure action if the delete failed
          dispatch({ type: DELETE_MEDS_COST_FAIL });
      }
  } catch (error) {
      console.log(error);
      dispatch({ type: DELETE_MEDS_COST_FAIL });
  }
};

// API Handler for Fuel Cost

export const fetchAllFuelCost = () => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    // Make an HTTP GET request to fetch batch data using the environment variable
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/fuel-cost/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const fuelData = response.data;
      dispatch({
        type: FUEL_COST_FETCH_SUCCESS,
        payload: fuelData,
      });
    } else {
      dispatch({
        type: FUEL_COST_FETCH_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching EGGS data:", error);
    dispatch({
      type: FUEL_COST_FETCH_FAIL,
    });
  }
};

export const saveFuelCost = ( total_cost, vehicle_type ) => async (dispatch, getState) => {
  const { access } = getState().auth;

  const config = {
      headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${access}`,
      },
      method: 'POST',
      body: JSON.stringify({ total_cost, vehicle_type })
  };

  try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/fuel-cost/`, config);

      if (res.ok) {
          const data = await res.json();
          dispatch({
            type: SAVE_FUEL_COST_SUCCESS,
            payload: data
          });
          return data;
      } else {
          const error = await res.json();
          dispatch({
            type: SAVE_FUEL_COST_FAIL,
            payload: error
          });
          return { success: false, error };
      }
  } catch (error) {
      return { success: false, error: 'Network error' };
  }
}

export const fetchFuelCostDetails = (id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/fuel-cost/${id}/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const fuelData = response.data.data; // Access data from the "data" key
      dispatch({
        type: FUEL_COST_FETCH_DETAILS_SUCCESS,
        payload: fuelData,
      });
      return fuelData
    } else {
      dispatch({
        type: FUEL_COST_FETCH_DETAILS_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching EGGS data:", error);
    dispatch({
      type: FUEL_COST_FETCH_DETAILS_FAIL,
    });
  }
}; 

export const editFuelCost = (selectedBatch) => async (dispatch, getState) => {
  const { access } = getState().auth;
  const {  vehicle_type, total_cost, id } = selectedBatch;

  const config = {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({ vehicle_type, total_cost }),
  };

  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/fuel-cost/${id}/`, config);

    if (res.ok) {
      const data = await res.json();
      dispatch({
        type: EDIT_FUEL_COST_SUCCESS,
        payload: data,
      });
      return {success: true, data};
    } else {
      const error = await res.json();
      dispatch({
        type: EDIT_FUEL_COST_FAIL,
        payload: error,
      });
      return { success: false, error };
    }
  } catch (error) {
    dispatch({
      type: EDIT_FUEL_COST_FAIL, // Change this to the correct action type
    });
    return { success: false, error: 'Network error' };
  }
};

export const deleteFuelCost = (id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
      const response = await Axios.delete(`${process.env.REACT_APP_API_URL}/api/fuel-cost/${id}/`, {
          headers: {
              Authorization: `Bearer ${access}`,
          },
      });

      if (response.status === 200) {
          // Dispatch a success action if the delete was successful
          dispatch({ type: DELETE_FUEL_COST_SUCCESS });

          // Dispatch an action to update the customer list
          dispatch({ type: FUEL_COST_UPDATE_LIST, payload: id }); // Send the deleted PAYMENT ID
      } else {
          // Dispatch a failure action if the delete failed
          dispatch({ type: DELETE_FUEL_COST_FAIL });
      }
  } catch (error) {
      console.log(error);
      dispatch({ type: DELETE_FUEL_COST_FAIL });
  }
};

// API Handler for Procurement Cost

export const fetchAllProcurementCost = () => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    // Make an HTTP GET request to fetch batch data using the environment variable
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/procurement/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const procurementData = response.data;
      dispatch({
        type: PROCUREMENT_COST_FETCH_SUCCESS,
        payload: procurementData,
      });
    } else {
      dispatch({
        type: PROCUREMENT_COST_FETCH_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching EGGS data:", error);
    dispatch({
      type: PROCUREMENT_COST_FETCH_FAIL,
    });
  }
};

export const saveProcurementCost = ( total_cost, number_of_chicks) => async (dispatch, getState) => {
  const { access } = getState().auth;

  const config = {
      headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${access}`,
      },
      method: 'POST',
      body: JSON.stringify({ total_cost, number_of_chicks })
  };

  try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/procurement/`, config);

      if (res.ok) {
          const data = await res.json();
          dispatch({
            type: SAVE_PROCUREMENT_COST_SUCCESS,
            payload: data
          });
          return data;
      } else {
          const error = await res.json();
          dispatch({
            type: SAVE_PROCUREMENT_COST_FAIL,
            payload: error
          });
          return { success: false, error };
      }
  } catch (error) {
      return { success: false, error: 'Network error' };
  }
}

export const fetchProcurementCostDetails = (id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
    const response = await Axios.get(`${process.env.REACT_APP_API_URL}/api/procurement/${id}/`, {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });

    if (response.status === 200) {
      const procurementData = response.data.data; // Access data from the "data" key
      dispatch({
        type: PROCUREMENT_COST_FETCH_DETAILS_SUCCESS,
        payload: procurementData,
      });
      return procurementData
    } else {
      dispatch({
        type: PROCUREMENT_COST_FETCH_DETAILS_FAIL,
      });
    }
  } catch (error) {
    console.error("Error fetching EGGS data:", error);
    dispatch({
      type: PROCUREMENT_COST_FETCH_DETAILS_FAIL,
    });
  }
}; 

export const editProcurementCost = (selectedBatch) => async (dispatch, getState) => {
  const { access } = getState().auth;
  const { number_of_chicks, total_cost, id } = selectedBatch;

  const config = {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${access}`,
    },
    body: JSON.stringify({ number_of_chicks, total_cost }),
  };

  try {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/procurement/${id}/`, config);

    if (res.ok) {
      const data = await res.json();
      dispatch({
        type: EDIT_PROCUREMENT_COST_SUCCESS,
        payload: data,
      });
      return {success: true, data};
    } else {
      const error = await res.json();
      dispatch({
        type: EDIT_PROCUREMENT_COST_FAIL,
        payload: error,
      });
      return { success: false, error };
    }
  } catch (error) {
    dispatch({
      type: EDIT_PROCUREMENT_COST_FAIL, // Change this to the correct action type
    });
    return { success: false, error: 'Network error' };
  }
};

export const deleteProcurementCost = (id) => async (dispatch, getState) => {
  const { access } = getState().auth;

  try {
      const response = await Axios.delete(`${process.env.REACT_APP_API_URL}/api/procurement/${id}/`, {
          headers: {
              Authorization: `Bearer ${access}`,
          },
      });

      if (response.status === 200) {
          // Dispatch a success action if the delete was successful
          dispatch({ type: DELETE_PROCUREMENT_COST_SUCCESS });

          // Dispatch an action to update the customer list
          dispatch({ type: PROCUREMENT_COST_UPDATE_LIST, payload: id }); // Send the deleted PAYMENT ID
      } else {
          // Dispatch a failure action if the delete failed
          dispatch({ type: DELETE_PROCUREMENT_COST_FAIL });
      }
  } catch (error) {
      console.log(error);
      dispatch({ type: DELETE_PROCUREMENT_COST_FAIL });
  }
};
