import React, { useState, useEffect } from 'react'
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import navLogo from '../assets/Shalom.png'
import { connect } from "react-redux";
import { logout } from '../actions/auth';
import { Link, useLocation } from 'react-router-dom';
import Config from '../utils/Config';

const NavBar = ({ logout, user, isSidebarOpen, isAuthenticated }) => {
    const [isSubMenuVisible, setSubMenuVisible] = useState(false);
    const [isBirdMenuVisible, setBirdMenuVisible] = useState(false);
    const [isEmpMenuVisible, setEmpMenuVisible] = useState(false);
    const [isOverheadMenuVisible, setOverheadMenuVisible] = useState(false);


    const location = useLocation();

    const isSubmenuActive = (url) => {
        return location.pathname === url;
    };

    const isBirdmenuActive = (url) => {
        return location.pathname === url;
    };

    const isEmpmenuActive = (url) => {
        return location.pathname === url;
    };

    const isOverheadmenuActive = (url) => {
        return location.pathname === url;
    };

    // Function to toggle the visibility of the submenu
    const toggleSubMenu = () => {
        setSubMenuVisible(!isSubMenuVisible);
    };

    const toggleBirdSubMenu = () => {
        setBirdMenuVisible(!isBirdMenuVisible);
    };

    const toggleEmpSubMenu = () => {
        setEmpMenuVisible(!isEmpMenuVisible);
    };

    const toggleOverheadSubMenu = () => {
        setOverheadMenuVisible(!isOverheadMenuVisible);
    };

    useEffect(() => {
        // Initialize perfect-scrollbar when the component mounts
        const ps = new PerfectScrollbar('#sidenav-main', {
          wheelPropagation: true,
        });
    
        // Destroy perfect-scrollbar when the component unmounts
        return () => {
          ps.destroy();
        };
      }, []);

    if (!isAuthenticated) {
        return null;
    }

    return (
        
        <aside className={`sidenav ${isSidebarOpen ? 'navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4 ps ps--active-y bg-white' : 'bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4 ps ps--active-y'}`} id="sidenav-main">
            
            <div className="sidenav-header">
                <i className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
                <Link className="navbar-brand m-0" to="/home" target="_blank">
                    <img src={navLogo} className="navbar-brand-img" alt="main_logo"/>
                    <span className="ms-1 font-weight-bold"></span>
                </Link>
            </div>

            <hr className="horizontal dark mt-0"/>

            <div className="collapse navbar-collapse w-auto h-auto ps" id="sidenav-collapse-main" >
                <ul className="navbar-nav">
                    {user && user.user_type === 'admin' && (
                        <li className="nav-item">
                            <Link data-bs-toggle="collapse" to={"/home"} className="nav-link" role="button">
                                <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                    <i className="ni ni-shop text-primary text-sm opacity-10"></i>
                                </div>
                                <span className="nav-link-text ms-1">Dashboard</span>
                            </Link>
                    
                        </li>
                    )}

                    <li className="nav-item mt-3">
                        <h6 className="ps-4  ms-2 text-uppercase text-xs font-weight-bolder opacity-6">MODULES</h6>
                    </li>

                    <li className="nav-item">
                        <a data-bs-toggle="collapse" onClick={toggleSubMenu} className="nav-link" role="button" aria-expanded={isSubMenuVisible ? "true" : "false"}>
                            <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                <i className="ni ni-chart-bar-32 text-success text-sm opacity-10"></i>
                            </div>
                            <span className="nav-link-text ms-1">Sales</span>
                        </a>

                        {isSubMenuVisible && (
                            <ul className="nav pl-4">
                                {Config.salesSidebar.map((item) => (
                                    <li className={`nav-link ${isSubmenuActive(item.url) ? 'active' : ''}`} key={item.index}>
                                        <Link to={item.url} className="nav-link" style={{ paddingLeft: '45px' }}>
                                            <i className={item.icons}></i>
                                            <span className="nav-link-text ms-1">{item.title}</span>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>

                    <li className="nav-item">
                        <a data-bs-toggle="collapse" onClick={toggleBirdSubMenu} className="nav-link " aria-controls="pagesExamples" role="button" aria-expanded={isBirdMenuVisible ? "true" : "false"}>
                            <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                <i className="fa-solid fa-feather-pointed text-danger text-sm opacity-10"></i>
                            </div>
                            <span className="nav-link-text ms-1">Birds</span>
                        </a>
                        {isBirdMenuVisible && (
                            <ul className="nav pl-4">
                                {Config.birdSidebar.map((item) => (
                                    <li className={`nav-link ${isBirdmenuActive(item.url) ? 'active' : ''}`} key={item.index}>
                                        <Link to={item.url} className="nav-link" style={{ paddingLeft: '45px' }}>
                                            <i className={item.icons}></i>
                                            <span className="nav-link-text ms-1">{item.title}</span>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                    
                    </li>
                    
                    {user && user.user_type === 'admin' && (
                        <li className="nav-item">
                        <a data-bs-toggle="collapse" onClick={toggleEmpSubMenu} className="nav-link " aria-controls="applicationsExamples" role="button" aria-expanded={isEmpMenuVisible ? "true" : "false"}>
                            <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                            <i className="fa-solid fa-users text-warning text-sm opacity-10"></i>
                            </div>
                            <span className="nav-link-text ms-1">Employees</span>
                        </a>

                        {isEmpMenuVisible && (
                            <ul className="nav pl-4">
                            {Config.employeeSidebar.map((item) => (
                                <li className={`nav-link ${isEmpmenuActive(item.url) ? 'active' : ''}`} key={item.index}>
                                <Link to={item.url} className="nav-link" style={{ paddingLeft: '45px' }}>
                                    <i className={item.icons}></i>
                                    <span className="nav-link-text ms-1">{item.title}</span>
                                </Link>
                                </li>
                            ))}
                            </ul>
                        )}
                        </li>
                    )}

                    {user && user.user_type === 'admin' && (
                        <li className="nav-item">
                        <a data-bs-toggle="collapse" onClick={toggleOverheadSubMenu} className="nav-link " aria-controls="applicationsExamples" role="button" aria-expanded={isOverheadMenuVisible ? "true" : "false"}>
                            <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                            <i className="ni ni-hat-3 text-info text-sm opacity-10"></i>
                            </div>
                            <span className="nav-link-text ms-1">Overheads</span>
                        </a>

                        {isOverheadMenuVisible && (
                            <ul className="nav pl-4">
                            {Config.overheadSidebar.map((item) => (
                                <li className={`nav-link ${isEmpmenuActive(item.url) ? 'active' : ''}`} key={item.index}>
                                <Link to={item.url} className="nav-link" style={{ paddingLeft: '45px' }}>
                                    <i className={item.icons}></i>
                                    <span className="nav-link-text ms-1">{item.title}</span>
                                </Link>
                                </li>
                            ))}
                            </ul>
                        )}
                        </li>
                    )}


                    <li className="nav-item">
                        <a data-bs-toggle="collapse" onClick={() => {logout(); window.location.href = "/";}} className="nav-link " aria-controls="authExamples" role="button" aria-expanded="false">
                            <div className="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
                                <i className="ni ni-lock-circle-open text-danger text-sm opacity-10"></i>
                            </div>
                            <span className="nav-link-text ms-1">Logout</span>
                        </a>
                    
                    </li>
                </ul>
            </div>

        </aside>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user
  });
  
  export default connect(mapStateToProps , { logout }) (NavBar);