import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify'; // Import ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for styling
import React, { useEffect, lazy, Suspense } from 'react';
import store from './store/store';
import { Provider } from 'react-redux';
import Layout from './hoc/Layout';
import { load_user } from './actions/auth'; // Import the load_user action

// Lazy-loaded components
const Login = lazy(() => import('./pages/auth/Login'));
const Signup = lazy(() => import('./pages/auth/Signup'));
const ResetPassword = lazy(() => import('./pages/auth/ResetPassword'));
const ResetPasswordConfirm = lazy(() => import('./pages/auth/ResetPasswordConfirm'));
const Activate = lazy(() => import('./pages/auth/Activate'));
const Home = lazy(() => import('./pages/general/Home'));
const Orders = lazy(() => import('./pages/sales/orders/Orders'));
const AddOrders = lazy(() => import('./pages/sales/orders/AddOders'));
const EditOrders = lazy(() => import('./pages/sales/orders/EditOrders'));
const Customer = lazy(() => import('./pages/sales/customer/Customer'));
const AddCustomer = lazy(() => import('./pages/sales/customer/AddCustomer'));
const EditCustomer = lazy(() => import('./pages/sales/customer/EditCustomer'));
const Payments = lazy(() => import('./pages/sales/payments/Payments'));
const AddPayments = lazy(() => import('./pages/sales/payments/AddPayments'));
const EditPayments = lazy(() => import('./pages/sales/payments/EditPayments'));
const SalesDashboard = lazy(() => import('./pages/sales/SalesDashboard'));
const Invoice = lazy(() => import('./pages/sales/invoice/Invoice'));
const ChickenBatch = lazy(() => import('./pages/birds/batch/ChickenBatch'));
const ViewBatch = lazy(() => import('./pages/birds/batch/ViewBatch'));
const Eggs = lazy(() => import('./pages/birds/eggs/Eggs'));
const BirdsDashboard = lazy(() => import('./pages/birds/BirdsDashboard'));
const EmployeesDashboard = lazy(() => import('./pages/employees/EmployeesDashboard'));
const Meat = lazy(() => import('./pages/birds/meat/Meat'));
const DownloadInvoice = lazy(() => import('./pages/sales/invoice/DownloadInvoice'));
const FoodIntake = lazy(() => import('./pages/birds/food/FoodIntake'));
const Meds = lazy(() => import('./pages/birds/medicine-vaccinations/Meds'));
const Employees = lazy(() => import('./pages/employees/Employees'));
const AddEmployess = lazy(() => import('./pages/employees/AddEmployess'));
const EditEmployee = lazy(() => import('./pages/employees/EditEmployee'));
const EmployeePayments = lazy(() => import('./pages/employees/payment/EmployeePayments'));
const FoodCost = lazy(() => import('./pages/overheads/FoodCost'));
const MedsCost = lazy(() => import('./pages/overheads/MedsCost'));
const Fuel = lazy(() => import('./pages/overheads/Fuel'));
const ChickProcurement = lazy(() => import('./pages/overheads/ChickProcurement'));

function App() {
  useEffect(() => {
    // Load user when the app loads
    store.dispatch(load_user());
  }, []);

  return (
      <Provider store={store}>
      <ToastContainer position="bottom-right" autoClose={5000} />
        <Router>
          <Suspense fallback={<div className="d-flex justify-content-center align-items-center min-vh-100">
            <div className="spinner-border text-success" role="status">
                  <span className="sr-only">Loading...</span>
              </div>
            </div>}
          >
            <Routes>
              {/* Auth Pages */}
              <Route exact path="/" element={<Login />}/>
              <Route exact path="/signup" element={<Signup />}/>
              <Route exact path="/activate/:uid/:token" element={<Activate />}/>
              <Route exact path="/reset-password" element={<ResetPassword />}/>
              <Route exact path="/password/reset/confirm/:uid/:token" element={<ResetPasswordConfirm />}/>
            </Routes>
            <Layout>
              <Routes>
              {/* General pages */} 
              <Route exact path="/home" element={<Home />}/>

              {/* / Orders */}
              <Route exact path="/neworders" element={<AddOrders />}/>
              <Route exact path="/orders" element={<Orders />}/>
              <Route exact path="/ordersdetails/:id" element={<EditOrders />}/>

              {/* Customers */}
              <Route exact path="/customers" element={<Customer />}/>
              <Route exact path="/addcustomer" element={<AddCustomer />}/>
              <Route exact path="/customerdetails/:id" element={<EditCustomer />}/>

              {/* Payments */}
              <Route exact path="/payments" element={<Payments />}/>
              <Route exact path="/addpayment" element={<AddPayments />}/>
              <Route exact path="/paymentsdetails/:id" element={<EditPayments />}/>

              {/* Invoice */}         
              <Route exact path="/invoice" element={<Invoice />} />
              <Route exact path="/download-invoice/:id" element={<DownloadInvoice />} />

              <Route exact path="/sales" element={<SalesDashboard />}/>

              {/* Bird routes */}
              <Route exact path='/batch' element={<ChickenBatch />}/>
              <Route exact path='/eggs' element={<Eggs />}/>
              <Route exact path='/batchdetails/:id' element={<ViewBatch />}/>
              <Route exact path='/meat' element={<Meat />}/>

              {/* Food routes */}
              <Route exact path='/chicken-feed' element={<FoodIntake />}/>

                {/* Medicnes & Vaccination routes */}
                <Route exact path='/meds' element={<Meds />}/>

              <Route exact path="/birds" element={<BirdsDashboard />}/>

              {/* Employee routes */}
              <Route exact path='/employee' element={<Employees />} />
              <Route exact path='/addemployee' element={<AddEmployess />} />
              <Route exact path='/employeedetails/:id' element={<EditEmployee />} />
              <Route exact path='/employee-payment' element={<EmployeePayments />} />
              <Route exact path="/employees" element={<EmployeesDashboard />}/>

              {/* Overheads routes */}
              <Route exact path='/food-bill' element={<FoodCost/>} />
              <Route exact path='/med-bill' element={<MedsCost />} />
              <Route exact path='/fuel' element={<Fuel />} />
              <Route exact path='/chicks' element={<ChickProcurement />} />

              </Routes>
            </Layout>
          </Suspense>
        </Router>
      </Provider>
  );
}

export default App;
