import { 

    // auth
    LOGIN_SUCCESS, LOGIN_FAIL, 
    LOADED_USER_SUCCESS, LOADED_USER_FAIL ,
    AUTHENTICATED_SUCCESS, AUTHENTICATED_FAIL,
    PASSWORD_RESET_SUCCESS, PASSWORD_RESET_FAIL,
    PASSWORD_RESET_CONFIRM_SUCCESS, PASSWORD_RESET_CONFIRM_FAIL,
    SIGNUP_SUCCESS, SIGNUP_FAIL,
    ACTIVATION_SUCCESS, ACTIVATION_FAIL,
    GOOGLE_AUTH_SUCCESS, GOOGLE_AUTH_FAIL,
    LOGOUT,

    // customers
    EDIT_CUSTOMER_SUCCESS, EDIT_CUSTOMER_FAIL,
    CUSTOMER_ONLY_FETCH_SUCCESS, CUSTOMER_ONLY_FETCH_FAIL,
    CUSTOMER_FETCH_ALL_SUCCESS, CUSTOMER_FETCH_ALL_FAIL,
    CUSTOMER_DELETE_SUCCESS, CUSTOMER_DELETE_FAIL, CUSTOMER_UPDATE_LIST,
    CUSTOMER_FETCH_DETAILS_SUCCESS, CUSTOMER_FETCH_DETAILS_FAIL, 
    SAVE_CUSTOMER_SUCCESS, SAVE_CUSTOMER_FAIL,

    // payments
    PAYMENTS_FETCH_ALL_SUCCESS, PAYMENTS_FETCH_ALL_FAIL,
    PAYMENTS_FETCH_DETAILS_SUCCESS, PAYMENTS_FETCH_DETAILS_FAIL,
    EDIT_PAYMENTS_SUCCESS, EDIT_PAYMENTS_FAIL,
    SAVE_PAYMENTS_SUCCESS, SAVE_PAYMENTS_FAIL,
    PAYMENT_DELETE_SUCCESS, PAYMENT_DELETE_FAIL, PAYMENT_UPDATE_LIST,

    // orders
    ORDERS_FETCH_ALL_SUCCESS, ORDERS_FETCH_ALL_FAIL,
    ORDERS_FETCH_DETAILS_SUCCESS, ORDERS_FETCH_DETAILS_FAIL,
    ORDER_DELETE_SUCCESS, ORDER_DELETE_FAIL, ORDER_UPDATE_LIST,
    SAVE_ORDERS_SUCCESS, SAVE_ORDERS_FAIL,
    EDIT_ORDERS_SUCCESS, EDIT_ORDERS_FAIL,

    // invoice
    INVOICE_FETCH_ALL_SUCCESS, INVOICE_FETCH_ALL_FAIL,
    INVOICE_FETCH_DETAILS_SUCCESS, INVOICE_FETCH_DETAILS_FAIL,
    INVOICE_DELETE_SUCCESS, INVOICE_DELETE_FAIL, INVOICE_UPDATE_LIST,
    SAVE_INVOICE_SUCCESS, SAVE_INVOICE_FAIL,
    EDIT_INVOICE_SUCCESS, EDIT_INVOICE_FAIL,

    // dashboard
    DASHBOARD_FETCH_SUCCESS, DASHBOARD_FETCH_FAIL,
    BALANCE_FETCH_SUCCESS, BALANCE_FETCH_FAIL,
    BIRDS_FETCH_SUCCESS, BIRDS_FETCH_FAIL,
    DAILYCHART_FETCH_FAIL, DAILYCHART_FETCH_SUCCESS, 
    MONTHLYCHART_FETCH_SUCCESS, MONTHLYCHART_FETCH_FAIL, 
    YEARLYCHART_FETCH_SUCCESS, YEARLYCHART_FETCH_FAIL,
    TRAYS_SOLD_FETCH_SUCCESS, TRAYS_SOLD_FETCH_FAIL,
    OVERPAID_FETCH_SUCCESS, OVERPAID_FETCH_FAIL,

    // chicken batch
    BATCH_FETCH_SUCCESS, BATCH_FETCH_FAIL,
    BATCH_ONLY_FETCH_SUCCESS, BATCH_ONLY_FETCH_FAIL,
    SAVE_BATCH_SUCCESS, SAVE_BATCH_FAIL,
    DELETE_BATCH_SUCCESS, DELETE_BATCH_FAIL, BATCH_UPDATE_LIST,
    BATCH_FETCH_DETAILS_SUCCESS, BATCH_FETCH_DETAILS_FAIL,
    EDIT_BATCH_SUCCESS, EDIT_BATCH_FAIL,

    // Eggs collection
    EGGS_FETCH_SUCCESS, EGGS_FETCH_FAIL,
    SAVE_EGGS_SUCCESS, SAVE_EGGS_FAIL,
    EDIT_EGGS_SUCCESS, EDIT_EGGS_FAIL,
    DELETE_EGGS_SUCCESS, DELETE_EGGS_FAIL, EGGS_UPDATE_LIST,
    EGGS_FETCH_DETAILS_SUCCESS, EGGS_FETCH_DETAILS_FAIL,

    // food intake
    FOOD_FETCH_SUCCESS, FOOD_FETCH_FAIL,
    SAVE_FOOD_SUCCESS, SAVE_FOOD_FAIL,
    EDIT_FOOD_SUCCESS, EDIT_FOOD_FAIL,
    DELETE_FOOD_SUCCESS, DELETE_FOOD_FAIL, FOOD_UPDATE_LIST,
    FOOD_FETCH_DETAILS_SUCCESS, FOOD_FETCH_DETAILS_FAIL,
    
    // Mortality rate
    MORTALITY_FETCH_SUCCESS, MORTALITY_FETCH_FAIL,
    SAVE_MORTALITY_SUCCESS, SAVE_MORTALITY_FAIL,
    EDIT_MORTALITY_SUCCESS, EDIT_MORTALITY_FAIL,
    DELETE_MORTALITY_SUCCESS, DELETE_MORTALITY_FAIL, MORTALITY_UPDATE_LIST,
    MORTALITY_FETCH_DETAILS_SUCCESS, MORTALITY_FETCH_DETAILS_FAIL,
    
    // Medicine and Vaccination
    MEDS_FETCH_SUCCESS, MEDS_FETCH_FAIL,
    SAVE_MEDS_SUCCESS, SAVE_MEDS_FAIL,
    EDIT_MEDS_SUCCESS, EDIT_MEDS_FAIL,
    DELETE_MEDS_SUCCESS, DELETE_MEDS_FAIL, MEDS_UPDATE_LIST,
    MEDS_FETCH_DETAILS_SUCCESS, MEDS_FETCH_DETAILS_FAIL,
    
    // Meat Production
    MEAT_FETCH_SUCCESS, MEAT_FETCH_FAIL,
    SAVE_MEAT_SUCCESS, SAVE_MEAT_FAIL,
    EDIT_MEAT_SUCCESS, EDIT_MEAT_FAIL,
    DELETE_MEAT_SUCCESS, DELETE_MEAT_FAIL, MEAT_UPDATE_LIST,
    MEAT_FETCH_DETAILS_SUCCESS, MEAT_FETCH_DETAILS_FAIL,
    
    // Employee Management
    EMPLOYEE_FETCH_SUCCESS, EMPLOYEE_FETCH_FAIL,
    SAVE_EMPLOYEE_SUCCESS, SAVE_EMPLOYEE_FAIL,
    EDIT_EMPLOYEE_SUCCESS, EDIT_EMPLOYEE_FAIL,
    DELETE_EMPLOYEE_SUCCESS, DELETE_EMPLOYEE_FAIL, EMPLOYEE_UPDATE_LIST,
    EMPLOYEE_FETCH_DETAILS_SUCCESS, EMPLOYEE_FETCH_DETAILS_FAIL,
    
    // Employee Payment Management
    EMPLOYEE_PAYMENT_FETCH_SUCCESS, EMPLOYEE_PAYMENT_FETCH_FAIL,
    EMPLOYEE_ONLY_FETCH_SUCCESS, EMPLOYEE_ONLY_FETCH_FAIL,
    SAVE_EMPLOYEE_PAYMENT_SUCCESS, SAVE_EMPLOYEE_PAYMENT_FAIL,
    EDIT_EMPLOYEE_PAYMENT_SUCCESS, EDIT_EMPLOYEE_PAYMENT_FAIL,
    DELETE_EMPLOYEE_PAYMENT_SUCCESS, DELETE_EMPLOYEE_PAYMENT_FAIL, EMPLOYEE_PAYMENT_UPDATE_LIST,
    EMPLOYEE_PAYMENT_FETCH_DETAILS_SUCCESS, EMPLOYEE_PAYMENT_FETCH_DETAILS_FAIL,
    
    // Food COST
    FEED_COST_FETCH_SUCCESS, FEED_COST_FETCH_FAIL,
    SAVE_FEED_COST_SUCCESS, SAVE_FEED_COST_FAIL,
    EDIT_FEED_COST_SUCCESS, EDIT_FEED_COST_FAIL,
    DELETE_FEED_COST_SUCCESS, DELETE_FEED_COST_FAIL, FEED_COST_UPDATE_LIST,
    FEED_COST_FETCH_DETAILS_SUCCESS, FEED_COST_FETCH_DETAILS_FAIL,
    
    // MEDS COST
    MEDS_COST_FETCH_SUCCESS, MEDS_COST_FETCH_FAIL,
    SAVE_MEDS_COST_SUCCESS, SAVE_MEDS_COST_FAIL,
    EDIT_MEDS_COST_SUCCESS, EDIT_MEDS_COST_FAIL,
    DELETE_MEDS_COST_SUCCESS, DELETE_MEDS_COST_FAIL, MEDS_COST_UPDATE_LIST,
    MEDS_COST_FETCH_DETAILS_SUCCESS, MEDS_COST_FETCH_DETAILS_FAIL,
    
    // FUEL COST
    FUEL_COST_FETCH_SUCCESS, FUEL_COST_FETCH_FAIL,
    SAVE_FUEL_COST_SUCCESS, SAVE_FUEL_COST_FAIL,
    EDIT_FUEL_COST_SUCCESS, EDIT_FUEL_COST_FAIL,
    DELETE_FUEL_COST_SUCCESS, DELETE_FUEL_COST_FAIL, FUEL_COST_UPDATE_LIST,
    FUEL_COST_FETCH_DETAILS_SUCCESS, FUEL_COST_FETCH_DETAILS_FAIL,

    // PROCUREMENT COST
    PROCUREMENT_COST_FETCH_SUCCESS, PROCUREMENT_COST_FETCH_FAIL,
    SAVE_PROCUREMENT_COST_SUCCESS, SAVE_PROCUREMENT_COST_FAIL,
    EDIT_PROCUREMENT_COST_SUCCESS, EDIT_PROCUREMENT_COST_FAIL,
    DELETE_PROCUREMENT_COST_SUCCESS, DELETE_PROCUREMENT_COST_FAIL, PROCUREMENT_COST_UPDATE_LIST,
    PROCUREMENT_COST_FETCH_DETAILS_SUCCESS, PROCUREMENT_COST_FETCH_DETAILS_FAIL,

} from '../actions/types'

const initialState = {
    access: localStorage.getItem('access'),
    refresh: localStorage.getItem('refresh'),
    isAuthenticated: null,
    user: null,
    customer: [],
    customerDetails: null, 
    paymentsDetails: null, 
    batchDetails: null,
    employeeDetails: null, 
    employeePaymentDetails: null,
    foodCostDetails: null, 
    medsCostDetails: null, 
    balance: 0,
    birds: 0,
    orders: [],
    payments: [],
    batch: [],
    eggs: [],
    invoice: [], 
    food: [],
    deaths: [],
    meds: [],
    meat: [],
    employee: [],
    employeePayment: [],
    foodCost:[],
    medsCost: [],
    fuel: [],
    procurement: []
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        // AUTHENTICATION & AUTHORIZATION REDUCERS

        case SIGNUP_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
            }    

        case LOGIN_SUCCESS:
            localStorage.setItem('access', payload.access);
            return {
                ...state,
                isAuthenticated: true,
                access: payload.access,
                refresh: payload.refresh
            }

        case LOADED_USER_SUCCESS:
            return{
                ...state,
                user: payload
            }

        case AUTHENTICATED_FAIL:
            return {
                ...state,
                isAuthenticated: false,
            }   
        
        case GOOGLE_AUTH_SUCCESS:
            localStorage.setItem('access', payload.access)
            return {
                ...state,
                isAuthenticated: true,
                access: payload.access,
                refresh: payload.refresh
            }

        case SIGNUP_FAIL:
        case GOOGLE_AUTH_FAIL:
        case LOGIN_FAIL:
        case LOGOUT:
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            return {
                ...state,
                access: null,
                refresh: null,
                isAuthenticated: false,
                user: null
            } 
            
        case PASSWORD_RESET_SUCCESS:
        case PASSWORD_RESET_FAIL:
        case PASSWORD_RESET_CONFIRM_SUCCESS:
        case PASSWORD_RESET_CONFIRM_FAIL:
        case ACTIVATION_SUCCESS:
        case ACTIVATION_FAIL:        
            return {
                ...state
            }

        case LOADED_USER_FAIL:
            return {
                ...state,
                user: null
            }
            
        case AUTHENTICATED_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
            } 

            // CUSTOMER MODULE REDUCERS

        case SAVE_CUSTOMER_SUCCESS:
            return {
                ...state,
                customers: payload.data,
            }

        case SAVE_CUSTOMER_FAIL:
            return{
                ...state,
            }

        case EDIT_CUSTOMER_SUCCESS:
            return {
                ...state,
                customers: payload.data,
            }

        case EDIT_CUSTOMER_FAIL:
            return{
                ...state,
                }
            
        case CUSTOMER_ONLY_FETCH_SUCCESS:
            return {
                ...state,
                customer: payload.data, // Store the fetched customer data
            };

        case CUSTOMER_ONLY_FETCH_FAIL:
            return {
                ...state,
                customer: [], // Handle the failure case
            }; 
                
        case CUSTOMER_FETCH_ALL_SUCCESS:
            return {
                ...state,
                customers: payload.data, // Store the fetched customer data
            };

        case CUSTOMER_FETCH_ALL_FAIL:
            return {
                ...state,
                customers: [], // Handle the failure case
            }; 

        case CUSTOMER_FETCH_DETAILS_SUCCESS:
            return {
                ...state,
                customerDetails: payload.data, // Store the fetched customer data
            };

        case CUSTOMER_FETCH_DETAILS_FAIL:
            return {
                ...state,
                customerDetails: [], // Store the fetched customer data
            };   

        case CUSTOMER_UPDATE_LIST:
            // Update the customer list by removing the deleted customer
            const updatedCustomer = state.customer.filter((customer) => customer.id !== payload);
            return {
                ...state,
                customer: updatedCustomer,
            };

        case CUSTOMER_DELETE_SUCCESS: 
            const updatedCustomers = state.customer.filter(customer => customer.id !== payload.data);

            return {
                ...state,
                customer: updatedCustomers,
            };

        case CUSTOMER_DELETE_FAIL:
            return {
                ...state,
            };       
        
            // ORDERS MODULE REDUCERS

        case SAVE_ORDERS_SUCCESS:
            return {
                ...state,
                orders: payload.data,
            }

        case SAVE_ORDERS_FAIL:
            return{
                ...state,
            } 

        case EDIT_ORDERS_SUCCESS:
            return {
                ...state,
                orders: payload.data,
            }

        case EDIT_ORDERS_FAIL:
            return {
                ...state,
            }

        case SAVE_ORDERS_FAIL:
            return{
                ...state,
            }    

        case ORDERS_FETCH_ALL_SUCCESS:
            return {
                ...state,
                orders: payload.data, // Store the fetched customer data
            };

        case ORDERS_FETCH_ALL_FAIL:
            return {
                ...state,
                orders: [], // Handle the failure case
            };

        case ORDERS_FETCH_DETAILS_SUCCESS:
            return {
                ...state,
                orders: payload.data, // Store the fetched orders data
            };

        case ORDERS_FETCH_DETAILS_FAIL:
            return {
                ...state,
                orders: [], // Store the fetched orders data
            };   
        
        case ORDER_UPDATE_LIST:
            // Update the customer list by removing the deleted customer
            const updatedOrder = state.orders.filter((orders) => orders.id !== payload);
            return {
                ...state,
                orders: updatedOrder,
            };

        case ORDER_DELETE_SUCCESS: 
            const updatedOrders = state.orders.filter(orders => orders.id !== payload.data);

            return {
                ...state,
                orders: updatedOrders,
            };

        case ORDER_DELETE_FAIL:
            return {
                ...state,
            };    
                      
            // INOICE MODULE REDUCERS
            
        case SAVE_INVOICE_SUCCESS:
            return {
                ...state,
                invoice: payload.data,
            };

        case SAVE_INVOICE_FAIL:
            return{
                ...state,
            } ;

        case EDIT_INVOICE_SUCCESS:
            return {
                ...state,
                invoice: payload.data,
            };

        case EDIT_INVOICE_FAIL:
            return {
                ...state,
            };

        case SAVE_INVOICE_FAIL:
            return{
                ...state,
            };    

        case INVOICE_FETCH_ALL_SUCCESS:
            return {
                ...state,
                invoice: payload.data, // Store the fetched customer data
            };

        case INVOICE_FETCH_ALL_FAIL:
            return {
                ...state,
                invoice: [], // Handle the failure case
            };

        case INVOICE_FETCH_DETAILS_SUCCESS:
            return {
                ...state,
                invoice: payload.data, // Store the fetched invoice data
            };

        case INVOICE_FETCH_DETAILS_FAIL:
            return {
                ...state,
                invoice: [], // Store the fetched invoice data
            };   
        
        case INVOICE_UPDATE_LIST:
            // Update the customer list by removing the deleted customer
            const updatedInvoice = state.invoice.filter((invoice) => invoice.id !== payload);
            return {
                ...state,
                invoice: updatedInvoice,
            };

        case INVOICE_DELETE_SUCCESS: 
            const updatedInvoices = state.invoice.filter(invoice => invoice.id !== payload.data);

            return {
                ...state,
                invoice: updatedInvoices,
            };

        case INVOICE_DELETE_FAIL:
            return {
                ...state,
            };    

            // PAYMENTS MODULE REDUCERS
        
        case PAYMENTS_FETCH_ALL_SUCCESS:
            return {
                ...state,
                payments: payload.data, // Store the fetched customer data
            };

        case PAYMENTS_FETCH_ALL_FAIL:
            return {
                ...state,
                payments: [], // Handle the failure case
            };

        case PAYMENTS_FETCH_DETAILS_SUCCESS:
            return {
                ...state,
                payments: payload.data, // Store the fetched payments data
            };

        case PAYMENTS_FETCH_DETAILS_FAIL:
            return {
                ...state,
                payments: [], // Store the fetched payments data
            };   
        
        case PAYMENT_UPDATE_LIST:
            // Update the customer list by removing the deleted customer
            const updatedPayment = state.payments.filter((payments) => payments.id !== payload);
            return {
                ...state,
                payments: updatedPayment,
            };

        case PAYMENT_DELETE_SUCCESS: 
            const updatedPayments = state.payments.filter(payments => payments.id !== payload.data);

            return {
                ...state,
                payments: updatedPayments,
            };

        case PAYMENT_DELETE_FAIL:
            return {
                ...state,
            };    
         
        case SAVE_PAYMENTS_SUCCESS:
            return {
                ...state,
                payments: payload.data,
            };

        case SAVE_PAYMENTS_FAIL:
            return{
                ...state,
            };   
            
        case EDIT_PAYMENTS_SUCCESS:
            return {
                ...state,
                payments: payload.data,
            };

        case EDIT_PAYMENTS_FAIL:
            return{
                ...state,
            };  

            // DASHBOARD MODULE REDUCERS

        case BALANCE_FETCH_SUCCESS:
            return {
                ...state,
                balance: payload
            }

        case BALANCE_FETCH_FAIL:
            return {
                ...state,
            } 

        case BIRDS_FETCH_SUCCESS:
            return {
                ...state,
                birds: payload
            }

        case BIRDS_FETCH_FAIL:
            return {
                ...state,
            } 

        case TRAYS_SOLD_FETCH_SUCCESS:
            return {
                ...state,
                traysSold: payload
            }

        case TRAYS_SOLD_FETCH_FAIL:
            return {
                ...state,
            }

        case OVERPAID_FETCH_SUCCESS:
            return {
                ...state,
                overpaid: payload
            }

        case OVERPAID_FETCH_FAIL:
            return {
                ...state,
            }

        case DASHBOARD_FETCH_SUCCESS:
            return {
                ...state,
                dashboard: payload
            }

        case DASHBOARD_FETCH_FAIL:
            return {
                ...state,
            }

        case DAILYCHART_FETCH_SUCCESS:
            return {
                ...state,
                dailychart: payload
            }

        case DAILYCHART_FETCH_FAIL:
            return {
                ...state,
            }

        case MONTHLYCHART_FETCH_SUCCESS:
            return {
                ...state,
                monthlychart: payload
            }

        case MONTHLYCHART_FETCH_FAIL:
            return {
                ...state,
            }

        case YEARLYCHART_FETCH_SUCCESS:
            return {
                ...state,
                yearlychart: payload
            }

        case YEARLYCHART_FETCH_FAIL:
            return {
                ...state,
            }

            // BIRDS MODULE REDUCERS

        case BATCH_FETCH_SUCCESS:
            return {
                ...state,
                batch: payload.data, // Store the fetched customer data
            };

        case BATCH_FETCH_FAIL:
            return {
                ...state,
                batch: [], // Handle the failure case
            }; 
            
        case BATCH_ONLY_FETCH_SUCCESS:
            return {
                ...state,
                batch: payload.data, // Store the fetched customer data
            };

        case BATCH_ONLY_FETCH_FAIL:
            return {
                ...state,
                batch: [], // Handle the failure case
            }; 
        
        case SAVE_BATCH_SUCCESS:
            return {
                ...state,
                batch: payload.data,
            }

        case SAVE_BATCH_FAIL:
            return{
                ...state,
            } 

        case EDIT_BATCH_SUCCESS:
            return {
                ...state,
                batch: payload.data,
            }

        case EDIT_BATCH_FAIL:
            return{
                ...state,
            } 

        case BATCH_FETCH_DETAILS_SUCCESS:
            return {
                ...state,
                batchDetails: payload.data, // Store the fetched BATCH data
            };

        case BATCH_FETCH_DETAILS_FAIL:
            return {
                ...state,
                batch: [], // Store the fetched BATCH data
            };   

        case DELETE_BATCH_SUCCESS: 
            const updatedBatch = state.batch.filter(batch => batch.id !== payload.data);

            return {
                ...state,
                batch: updatedBatch,
            };

        case DELETE_BATCH_FAIL:
            return {
                ...state,
            };     
            
        case BATCH_UPDATE_LIST:
            // Update the customer list by removing the deleted customer
            const updatedBatches = state.batch.filter((batch) => batch.id !== payload);
            return {
                ...state,
                batch: updatedBatches,
            };

        // EGGS MODULE REDUCERS

        case EGGS_FETCH_SUCCESS:
            return {
                ...state,
                eggs: payload.data, // Store the fetched customer data
            };

        case EGGS_FETCH_FAIL:
            return {
                ...state
            }

        case SAVE_EGGS_SUCCESS:
            return {
                ...state,
                eggs: payload.data,
            }

        case SAVE_EGGS_FAIL:
            return{
                ...state,
            } 

        case EDIT_EGGS_SUCCESS:
            return {
                ...state,
                eggs: payload.data,
            }

        case EDIT_EGGS_FAIL:
            return{
                ...state,
            } 

        case EGGS_FETCH_DETAILS_SUCCESS:
            return {
                ...state,
                eggs: payload.data, // Store the fetched EGGS data
            };

        case EGGS_FETCH_DETAILS_FAIL:
            return {
                ...state,
                eggs: [], // Store the fetched EGGS data
            };   

        case DELETE_EGGS_SUCCESS: 
            const updatedEggs = state.eggs.filter(eggs => eggs.id !== payload.data);

            return {
                ...state,
                eggs: updatedEggs,
            };

        case DELETE_EGGS_FAIL:
            return {
                ...state,
            };     
            
        case EGGS_UPDATE_LIST:
            // Update the customer list by removing the deleted customer
            const updatedEgg = state.eggs.filter((eggs) => eggs.id !== payload);
            return {
                ...state,
                eggs: updatedEgg,
            };

             // FOOD MODULE REDUCERS

        case FOOD_FETCH_SUCCESS:
            return {
                ...state,
                food: payload.data, // Store the fetched customer data
            };

        case FOOD_FETCH_FAIL:
            return {
                ...state
            }

        case SAVE_FOOD_SUCCESS:
            return {
                ...state,
                food: payload.data,
            }

        case SAVE_FOOD_FAIL:
            return{
                ...state,
            } 

        case EDIT_FOOD_SUCCESS:
            return {
                ...state,
                food: payload.data,
            }

        case EDIT_FOOD_FAIL:
            return{
                ...state,
            } 

        case FOOD_FETCH_DETAILS_SUCCESS:
            return {
                ...state,
                food: payload.data, // Store the fetched FOOD data
            };

        case FOOD_FETCH_DETAILS_FAIL:
            return {
                ...state,
                food: [], // Store the fetched FOOD data
            };   

        case DELETE_FOOD_SUCCESS: 
            const updatedFood = state.food.filter(food => food.id !== payload.data);

            return {
                ...state,
                food: updatedFood,
            };

        case DELETE_FOOD_FAIL:
            return {
                ...state,
            };     
            
        case FOOD_UPDATE_LIST:
            // Update the customer list by removing the deleted customer
            const updatedFeed = state.food.filter((food) => food.id !== payload);
            return {
                ...state,
                food: updatedFeed,
            };
            
             // MORTALITY MODULE REDUCERS

        case MORTALITY_FETCH_SUCCESS:
            return {
                ...state,
                deaths: payload.data, // Store the fetched customer data
            };

        case MORTALITY_FETCH_FAIL:
            return {
                ...state
            }

        case SAVE_MORTALITY_SUCCESS:
            return {
                ...state,
                deaths: payload.data,
            }

        case SAVE_MORTALITY_FAIL:
            return{
                ...state,
            } 

        case EDIT_MORTALITY_SUCCESS:
            return {
                ...state,
                deaths: payload.data,
            }

        case EDIT_MORTALITY_FAIL:
            return{
                ...state,
            } 

        case MORTALITY_FETCH_DETAILS_SUCCESS:
            return {
                ...state,
                deaths: payload.data, // Store the fetched MORTALITY data
            };

        case MORTALITY_FETCH_DETAILS_FAIL:
            return {
                ...state,
                deaths: [], // Store the fetched MORTALITY data
            };   

        case DELETE_MORTALITY_SUCCESS: 
            const updatedDeath = state.deaths.filter(deaths => deaths.id !== payload.data);

            return {
                ...state,
                deaths: updatedDeath,
            };

        case DELETE_MORTALITY_FAIL:
            return {
                ...state,
            };     
            
        case MORTALITY_UPDATE_LIST:
            // Update the customer list by removing the deleted customer
            const updatedDeaths = state.deaths.filter((deaths) => deaths.id !== payload);
            return {
                ...state,
                deaths: updatedDeaths,
            };

            // MORTALITY MODULE REDUCERS

        case MEDS_FETCH_SUCCESS:
            return {
                ...state,
                meds: payload.data, // Store the fetched customer data
            };

        case MEDS_FETCH_FAIL:
            return {
                ...state
            }

        case SAVE_MEDS_SUCCESS:
            return {
                ...state,
                meds: payload.data,
            }

        case SAVE_MEDS_FAIL:
            return{
                ...state,
            } 

        case EDIT_MEDS_SUCCESS:
            return {
                ...state,
                meds: payload.data,
            }

        case EDIT_MEDS_FAIL:
            return{
                ...state,
            } 

        case MEDS_FETCH_DETAILS_SUCCESS:
            return {
                ...state,
                meds: payload.data, // Store the fetched MEDS data
            };

        case MEDS_FETCH_DETAILS_FAIL:
            return {
                ...state,
                meds: [], // Store the fetched MEDS data
            };   

        case DELETE_MEDS_SUCCESS: 
            const updatedMed = state.meds.filter(meds => meds.id !== payload.data);

            return {
                ...state,
                meds: updatedMed,
            };

        case DELETE_MEDS_FAIL:
            return {
                ...state,
            };     
            
        case MEDS_UPDATE_LIST:
            // Update the customer list by removing the deleted customer
            const updatedMeds = state.meds.filter((meds) => meds.id !== payload);
            return {
                ...state,
                meds: updatedMeds,
            };

            // MORTALITY MODULE REDUCERS

        case MEAT_FETCH_SUCCESS: 
            return {
                ...state,
                meat: payload.data, // Store the fetched customer data
            };

        case MEAT_FETCH_FAIL:
            return {
                ...state
            }

        case SAVE_MEAT_SUCCESS:
            return {
                ...state,
                meat: payload.data,
            }

        case SAVE_MEAT_FAIL:
            return{
                ...state,
            } 

        case EDIT_MEAT_SUCCESS:
            return {
                ...state,
                meat: payload.data,
            }

        case EDIT_MEAT_FAIL:
            return{
                ...state,
            } 

        case MEAT_FETCH_DETAILS_SUCCESS:
            return {
                ...state,
                meat: payload.data, // Store the fetched MEAT data
            };

        case MEAT_FETCH_DETAILS_FAIL:
            return {
                ...state,
                meat: [], // Store the fetched MEAT data
            };   

        case DELETE_MEAT_SUCCESS: 
            const updatedMeat = state.meat.filter(meat => meat.id !== payload.data);

            return {
                ...state,
                meat: updatedMeat,
            };

        case DELETE_MEAT_FAIL:
            return {
                ...state,
            };     
            
        case MEAT_UPDATE_LIST:
            // Update the customer list by removing the deleted customer
            const updateMeat = state.meat.filter((meat) => meat.id !== payload);
            return {
                ...state,
                meat: updateMeat,
            };

            // EMPLOYEE MODULE REDUCERS

        case EMPLOYEE_FETCH_SUCCESS: 
            return {
                ...state,
                employee: payload.data, // Store the fetched customer data
            };

        case EMPLOYEE_FETCH_FAIL:
            return {
                ...state
            }

        case EMPLOYEE_ONLY_FETCH_SUCCESS:
            return {
                ...state,
                employee: payload.data, // Store the fetched customer data
            };

        case EMPLOYEE_ONLY_FETCH_FAIL:
            return {
                ...state,
                employee: [], // Handle the failure case
            };         

        case SAVE_EMPLOYEE_SUCCESS:
            return {
                ...state,
                employee: payload.data,
            }

        case SAVE_EMPLOYEE_FAIL:
            return{
                ...state,
            } 

        case EDIT_EMPLOYEE_SUCCESS:
            return {
                ...state,
                employee: payload.data,
            }

        case EDIT_EMPLOYEE_FAIL:
            return{
                ...state,
            } 

        case EMPLOYEE_FETCH_DETAILS_SUCCESS:
            return {
                ...state,
                employeeDetails: payload.data, // Store the fetched EMPLOYEE data
            };

        case EMPLOYEE_FETCH_DETAILS_FAIL:
            return {
                ...state,
                employeeDetails: [], // Store the fetched EMPLOYEE data
            };   

        case DELETE_EMPLOYEE_SUCCESS: 
            const updatedEmployee = state.employee.filter(employee => employee.id !== payload.data);

            return {
                ...state,
                employee: updatedEmployee,
            };

        case DELETE_EMPLOYEE_FAIL:
            return {
                ...state,
            };     

        case EMPLOYEE_UPDATE_LIST:
            // Update the customer list by removing the deleted customer
            const updatedEmployees = state.employee.filter((employee) => employee.id !== payload);
            return {
                ...state,
                employee: updatedEmployees,
            };

            // EMPLOYEE PAYMENT MODULE REDUCERS

        case EMPLOYEE_PAYMENT_FETCH_SUCCESS: 
            return {
                ...state,
                employeePayment: payload.data, // Store the fetched customer data
            };

        case EMPLOYEE_PAYMENT_FETCH_FAIL:
            return {
                ...state
            }

        case SAVE_EMPLOYEE_PAYMENT_SUCCESS:
            return {
                ...state,
                employeePayment: payload.data,
            }

        case SAVE_EMPLOYEE_PAYMENT_FAIL:
            return{
                ...state,
            } 

        case EDIT_EMPLOYEE_PAYMENT_SUCCESS:
            return {
                ...state,
                employeePayment: payload.data,
            }

        case EDIT_EMPLOYEE_PAYMENT_FAIL:
            return{
                ...state,
            } 

        case EMPLOYEE_PAYMENT_FETCH_DETAILS_SUCCESS:
            return {
                ...state,
                employeePaymentDetails: payload.data, // Store the fetched EMPLOYEE data
            };

        case EMPLOYEE_PAYMENT_FETCH_DETAILS_FAIL:
            return {
                ...state,
                employeePaymentDetails: [], // Store the fetched EMPLOYEE data
            };   

        case DELETE_EMPLOYEE_PAYMENT_SUCCESS: 
            const updatedEmployeePayments = state.employee.filter(employee => employee.id !== payload.data);

            return {
                ...state,
                employeePayment: updatedEmployeePayments,
            };

        case DELETE_EMPLOYEE_PAYMENT_FAIL:
            return {
                ...state,
            };     

        case EMPLOYEE_PAYMENT_UPDATE_LIST:
            // Update the customer list by removing the deleted customer
            const updatedEmployeePayment = state.employee.filter((employee) => employee.id !== payload);
            return {
                ...state,
                employeePayment: updatedEmployeePayment,
            };
         
        // FOODS COST MODULE REDUCERS

        case FEED_COST_FETCH_SUCCESS:
            return {
                ...state,
                foodCost: payload.data, // Store the fetched customer data
            };

        case FEED_COST_FETCH_FAIL:
            return {
                ...state
            }

        case SAVE_FEED_COST_SUCCESS:
            return {
                ...state,
                foodCost: payload.data,
            }

        case SAVE_FEED_COST_FAIL:
            return{
                ...state,
            } 

        case EDIT_FEED_COST_SUCCESS:
            return {
                ...state,
                foodCost: payload.data,
            }

        case EDIT_FEED_COST_FAIL:
            return{
                ...state,
            } 

        case FEED_COST_FETCH_DETAILS_SUCCESS:
            return {
                ...state,
                foodCostDetails: payload.data, // Store the fetched FEED_COST data
            };

        case FEED_COST_FETCH_DETAILS_FAIL:
            return {
                ...state,
                foodCostDetails: [], // Store the fetched FEED_COST data
            };   

        case DELETE_FEED_COST_SUCCESS: 
            const updatedfoodCost = state.foodCost.filter(foodCost => foodCost.id !== payload.data);

            return {
                ...state,
                foodCost: updatedfoodCost,
            };

        case DELETE_FEED_COST_FAIL:
            return {
                ...state,
            };     
            
        case FEED_COST_UPDATE_LIST:
            // Update the customer list by removing the deleted customer
            const updatedFoodCost = state.foodCost.filter((foodCost) => foodCost.id !== payload);
            return {
                ...state,
                foodCost: updatedFoodCost,
            };
            
        // MEDS COST MODULE REDUCERS

        case MEDS_COST_FETCH_SUCCESS:
            return {
                ...state,
                medsCost: payload.data, // Store the fetched customer data
            };

        case MEDS_COST_FETCH_FAIL:
            return {
                ...state
            }

        case SAVE_MEDS_COST_SUCCESS:
            return {
                ...state,
                medsCost: payload.data,
            }

        case SAVE_MEDS_COST_FAIL:
            return{
                ...state,
            } 

        case EDIT_MEDS_COST_SUCCESS:
            return {
                ...state,
                medsCost: payload.data,
            }

        case EDIT_MEDS_COST_FAIL:
            return{
                ...state,
            } 

        case MEDS_COST_FETCH_DETAILS_SUCCESS:
            return {
                ...state,
                medsCostDetails: payload.data, // Store the fetched medsCostDetails data
            };

        case MEDS_COST_FETCH_DETAILS_FAIL:
            return {
                ...state,
                medsCostDetails: [], // Store the fetched medsCostDetails data
            };   

        case DELETE_MEDS_COST_SUCCESS: 
            const updatedmedsCost = state.medsCost.filter(medsCost => medsCost.id !== payload.data);

            return {
                ...state,
                medsCost: updatedmedsCost,
            };

        case DELETE_MEDS_COST_FAIL:
            return {
                ...state,
            };     
            
        case MEDS_COST_UPDATE_LIST:
            // Update the customer list by removing the deleted customer
            const updatedMedsCost = state.medsCost.filter((medsCost) => medsCost.id !== payload);
            return {
                ...state,
                medsCost: updatedMedsCost,
            };
    
        // FUEL COST MODULE REDUCERS

        case FUEL_COST_FETCH_SUCCESS:
            return {
                ...state,
                fuel: payload.data, // Store the fetched customer data
            };

        case FUEL_COST_FETCH_FAIL:
            return {
                ...state
            }

        case SAVE_FUEL_COST_SUCCESS:
            return {
                ...state,
                fuel: payload.data,
            }

        case SAVE_FUEL_COST_FAIL:
            return{
                ...state,
            } 

        case EDIT_FUEL_COST_SUCCESS:
            return {
                ...state,
                fuel: payload.data,
            }

        case EDIT_FUEL_COST_FAIL:
            return{
                ...state,
            } 

        case FUEL_COST_FETCH_DETAILS_SUCCESS:
            return {
                ...state,
                fuelDetails: payload.data, // Store the fetched fuelDetails data
            };

        case FUEL_COST_FETCH_DETAILS_FAIL:
            return {
                ...state,
                fuelDetails: [], // Store the fetched fuelDetails data
            };   

        case DELETE_FUEL_COST_SUCCESS: 
            const updatedFuelCost = state.fuel.filter(fuel => fuel.id !== payload.data);

            return {
                ...state,
                fuel: updatedFuelCost,
            };

        case DELETE_FUEL_COST_FAIL:
            return {
                ...state,
            };     
            
        case FUEL_COST_UPDATE_LIST:
            // Update the customer list by removing the deleted customer
            const updatedFuelCosts = state.fuel.filter((fuel) => fuel.id !== payload);
            return {
                ...state,
                fuel: updatedFuelCosts,
            };

            
        // PROCUREMENT COST MODULE REDUCERS

        case PROCUREMENT_COST_FETCH_SUCCESS:
            return {
                ...state,
                procurement: payload.data, // Store the fetched customer data
            };

        case PROCUREMENT_COST_FETCH_FAIL:
            return {
                ...state
            }

        case SAVE_PROCUREMENT_COST_SUCCESS:
            return {
                ...state,
                procurement: payload.data,
            }

        case SAVE_PROCUREMENT_COST_FAIL:
            return{
                ...state,
            } 

        case EDIT_PROCUREMENT_COST_SUCCESS:
            return {
                ...state,
                procurement: payload.data,
            }

        case EDIT_PROCUREMENT_COST_FAIL:
            return{
                ...state,
            } 

        case PROCUREMENT_COST_FETCH_DETAILS_SUCCESS:
            return {
                ...state,
                procurementDetails: payload.data, // Store the fetched procurementDetails data
            };

        case PROCUREMENT_COST_FETCH_DETAILS_FAIL:
            return {
                ...state,
                procurementDetails: [], // Store the fetched PROCUREMENTCostDetails data
            };   

        case DELETE_PROCUREMENT_COST_SUCCESS: 
            const updatedProcurementCost = state.procurement.filter(procurement => procurement.id !== payload.data);

            return {
                ...state,
                procurement: updatedProcurementCost,
            };

        case DELETE_PROCUREMENT_COST_FAIL:
            return {
                ...state,
            };     
            
        case PROCUREMENT_COST_UPDATE_LIST:
            // Update the customer list by removing the deleted customer
            const updatedProcurementCosts = state.procurement.filter((procurement) => procurement.id !== payload);
            return {
                ...state,
                procurement: updatedProcurementCosts,
            };
    
        default:
            return state;
    }
}
