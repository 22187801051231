class Config{

    static salesSidebar = [
        {
            index: "1",
            title: "New Orders",
            url: "/neworders",
            icons: "ni ni-money-coins"
        },
        {
            index: "2",
            title: "Customer",
            url: "/customers",
            icons: "ni ni-single-02"
        },
        {
            index: "3",
            title: "Orders",
            url: "/orders",
            icons: "ni ni-cart"
        },
        {
            index: "4",
            title: "Payments",
            url: "/payments",
            icons: "fas fa-university"
        },
        {
            index: "5",
            title: "Invoice",
            url: "/invoice",
            icons: "fa-solid fa-receipt"
        }
    ]

    static birdSidebar = [
        {
            index: "7",
            title: "Eggs Production",
            url: "/eggs",
            icons: "fa-solid fa-egg"
        },
        {
            index: "8",
            title: "Chicken Batch",
            url: "/batch",
            icons: "fa-brands fa-earlybirds"
        },
        {
            index: "9",
            title: "Meat Processing",
            url: "/meat",
            icons: "fa-solid fa-drumstick-bite"
        },
        {
            index: "10",
            title: "Chicken Feed",
            url: "/chicken-feed",
            icons: "fa-solid fa-kiwi-bird"
        },
        {
            index: "11",
            title: "Medicine & Vaccine",
            url: "/meds",
            icons: "fa-solid fa-syringe"
        },
        {
            index: "12",
            title: "Birds Analytics",
            url: "/birds",
            icons: "fas fa-pie-chart"
        },
    ]

    static employeeSidebar = [
        {
            index: "13",
            title: "Employees",
            url: "/employee",
            icons: "fa-solid fa-users"
        },
        {
            index: "14",
            title: "Employee Payments",
            url: "/employee-payment",
            icons: "fa-solid fa-wallet"
        },
        {
            index: "15",
            title: "Employee Analytics",
            url: "/employees",
            icons: "fas fa-pie-chart"
        },
    ]

    static overheadSidebar = [
        {
            index: "16",
            title: "Feed Cost",
            url: "/food-bill",
            icons: "fa-solid fa-money-bill-wheat"
        },
        {
            index: "17",
            title: "Meds Cost",
            url: "/med-bill",
            icons: "fa-solid fa-syringe"
        },
        {
            index: "18",
            title: "Fuel Cost",
            url: "/fuel",
            icons: "fa-solid fa-gas-pump"
        },
        {
            index: "19",
            title: "Chicks Procurement",
            url: "/chicks",
            icons: "fi fi-ss-dove"
        }
    ]
}

export default Config